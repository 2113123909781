import React from 'react';
import "./../../styles.scss"
import './solvInNews.scss';
import CommonCard from './commonCard/commonCard'
import DragScroll from '../common/dragScroll/DragScroll'

const SolvInNews = () => {
    const cardPosts = [
        {
            id: '1',
            heading: `Solv bags $40 MN in Series A`,
            subHeading: `Bengaluru-based early-stage venture capital firm, Fundamental VC launches its maiden fund with a target corpus of $130 million. The fund received SEBI’s approval in March this year and has already started deploying capital with plans to lead investments in pre-seed and seed rounds.`,
            image: 'cnbc',
            publication: 'CNBC 18',
            showImage: true,
            url: 'https://www.cnbctv18.com/videos/startup/startup-street-key-concerns-of-msmes-in-india-fundamental-vcs-130-mn-maiden-fund-13953382.htm',
            postedDate: '27 June 2022'
        },
        {
            id: '2',
            heading: `B2B marketplace Solv raises $40 million in funding led by SBI Holdings`,
            subHeading: `Solv, a marketplace for small businesses, has raised $40 million (around Rs 300 crore) in a round of funding led by Japan-headquartered SBI Holdings, with participation from SC Ventures, which has been an incubator and early-stage investor of the B2B digital marketplace. This round takes Solv’s total funding to nearly $80 million.`,
            image: 'ET',
            publication: 'Economic Times',
            showImage: true,
            url: 'https://economictimes.indiatimes.com/tech/funding/b2b-marketplace-solv-raises-40-million-in-funding-led-by-sbi-holdings/articleshow/92491337.cms',
            postedDate: '27 June 2022'
        },
        {
            id: '3',
            heading: `Solv raises $40 million in funding round led by Japan's SBI Holdings`,
            subHeading: `Solv, a marketplace for small businesses, on Monday said it has raised USD 40 million (about Rs 310 crore) in funding led by Japan's SBI Holdings.`,
            image: 'BS',
            publication: 'Business Standard',
            showImage: true,
            url: 'https://www.business-standard.com/article/companies/solv-raises-40-million-in-funding-round-led-by-japan-s-sbi-holdings-122062700386_1.html',
            postedDate: '27 June 2022'
        },
        {
            id: '4',
            heading: `B2B digital marketplace Solv raises $40M in funding led by SBI Holdings`,
            subHeading: `The stratup will use the fresh funds to launch additional high-margin product categories and expand to 300+ cities in India. The funding will also drive expansion of BNPL (Buy Now Pay Later) loans, particularly in the NTC (New-To-Credit) segment.`,
            image: 'yourStory',
            publication: 'Your Story',
            showImage: true,
            url: 'https://yourstory.com/2022/06/funding-b2b-digital-marketplace-solv-sbi-holdings/amp',
            postedDate: '27 June 2022'
        },
        {
            id: '5',
            heading: `B2B platform Solv raises $40 million in Series A round led by SBI Holdings`,
            subHeading: `Solv clocked over $260 million GMV run-rate in the first half of 2022 with about 60 per cent average repeated purchases.`,
            image: 'HBL',
            publication: 'The Hindu Business Line',
            showImage: true,
            url: 'https://www.thehindubusinessline.com/companies/b2b-platform-solv-raises-40-million-in-series-a-round-led-by-sbi-holdings/article65569529.ece',
            postedDate: '27 June 2022'
        },
        {
            id: '6',
            heading: `Japan’s SBI Holdings bets on B2B MSME marketplace Solv`,
            subHeading: `Solv, a business-to-business digital marketplace for micro, small and medium enterprises (MSMEs), has raised $40 million (Rs 300 crore) in its Series A funding round led by Japan’s SBI Holdings.`,
            image: 'vcCircle',
            publication: 'VC Circle',
            showImage: true,
            url: 'https://www.vccircle.com/japan-s-sbi-holdings-bets-on-b2b-msme-marketplace-solv',
            postedDate: '27 June 2022'
        },
        {
            id: '7',
            heading: `MSME marketplace Solv raises $40mn`,
            subHeading: `Solv, a B2B digital marketplace for MSMEs, has raiseed $40 million in a funding round led by SBI Holding.`,
            image: 'TOI',
            publication: 'Times of India',
            showImage: true,
            url: 'https://timesofindia.indiatimes.com/city/bengaluru/msme-marketplace-solv-raises-40mn/articleshow/92507018.cms',
            postedDate: '27 June 2022'
        },
        {
            id: '8',
            heading: `Solv to deploy EVs for deliveries`,
            subHeading: `Solv, the B2B e-commerce platform for MSMEs, announced that it will shift to electric vehicles (EVs) for its delivery services starting today. The initiative christened Solv EVolve expects 25 percent of all goods deliveries to MSMEs will be fulfilled within a year using EVs.`,
            image: 'FE',
            publication: 'Financial Express',
            showImage: true,
            url: 'https://www.financialexpress.com/express-mobility/vehicles/electric-vehicles/solv-to-deploy-evs-for-deliveries/2537672/',
            postedDate: '25 May 2022'
        },
        {
            id: '9',
            heading: `The Business secrets held in India's nutella jars`,
            subHeading: `90% of the country’s trade passes through neighborhood 'kirana' shops, each with annual sales of $0.5 million or less.Their owners mostly operate without business registration and lack access to the formal banking system.`,
            image: 'washingtonPost',
            publication: 'The Washington Post',
            showImage: true,
            url: 'https://www.washingtonpost.com/business/the-business-secrets-held-in-indias-nutella-jars/2022/02/26/664af5f6-9769-11ec-bb31-74fc06c0a3a5_story.html',
            postedDate: '28 Feb 2022'
        },
        {
            id: '10',
            heading: `The Business secrets held in India's nutella jars`,
            subHeading: `90% of the country’s trade passes through neighborhood 'kirana' shops, each with annual sales of $0.5 million or less.Their owners mostly operate without business registration and lack access to the formal banking system.`,
            image: 'mint',
            publication: 'Mint',
            showImage: true,
            url: 'https://www.livemint.com/companies/news/the-business-secrets-held-in-india-s-nutella-jars-11646005730728.html',
            postedDate: '28 Feb 2022'
        },
        {
            id: '11',
            heading: `The Business secrets held in India's nutella jars`,
            subHeading: `90% of the country’s trade passes through neighborhood 'kirana' shops, each with annual sales of $0.5 million or less.Their owners mostly operate without business registration and lack access to the formal banking system.`,
            image: 'ET',
            publication: 'The Economic Times',
            showImage: true,
            url: 'https://m.economictimes.com/small-biz/sme-sector/the-business-secrets-held-in-indias-nutella-jars/articleshow/89883363.cms',
            postedDate: '28 Feb 2022'
        },
        {
            id: '12',
            heading: `The Business secrets held in India's nutella jars`,
            subHeading: `Small retailers in India have always been neglected by banks. Standard Chartered is taking a granular approach to helping them get credit.`,
            image: 'bloomberg',
            publication: 'Bloomberg Opinion',
            showImage: true,
            url: 'https://www.bloomberg.com/opinion/articles/2022-02-27/standard-chartered-takes-a-granular-approach-to-small-business-credit-in-india?utm_medium=social&utm_source=twitter&utm_content=view&cmpid%3D=socialflow-twitter-view&utm_campaign=socialflow-organic',
            postedDate: '28 Feb 2022'
        },
        {
            id: '13',
            heading: `Digitization In the Post-Pandemic World: A Unique Combination Of Factors Is Helping the Indian MSME Sector Become 'Atmanirbhar'`,
            subHeading: ``,
            image: 'entrepreneurMagazine',
            publication: 'Entrepreneur Magazine logo',
            showImage: true,
            url: 'https://www.entrepreneur.com/article/366512',
            postedDate: 'Mar 4, 2021'
        },
        {
            id: '14',
            heading: `India's Trusted Online B2B Marketplace Solv Launches Operations in Hyderabad`,
            subHeading: ``,
            image: 'theWeek',
            publication: 'The Week logo',
            showImage: true,
            url: 'https://www.theweek.in/wire-updates/business/2021/02/18/pwr8-solv.html',
            postedDate: 'Feb 18, 2021'
        },
        {
            id: '15',
            heading: `Stan-Chart-led SME platform eyes funds from multi-lateral instts `,
            subHeading: ``,
            image: 'TOI',
            publication: 'Times of India logo',
            showImage: true,
            url: 'https://timesofindia.indiatimes.com/business/india-business/stanchart-led-sme-platform-solv-eyes-funds-from-multilateral-instts/articleshow/80914423.cms ',
            postedDate: 'Feb 15, 2021'
        },
        {
            id: '16',
            heading: `Solv launches 'Buy Now, Pay Later' for MSME sector`,
            subHeading: ``,
            image: 'ET',
            publication: 'The Economic Times Logo',
            showImage: true,
            url: 'https://economictimes.indiatimes.com/small-biz/sme-sector/solv-launches-of-buy-now-pay-later-for-msme-sector/articleshow/80324917.cms?utm_source=contentofinterest&utm_medium=text&utm_campaign=cppst',
            postedDate: 'Jan 20, 2021'
        },
        {
            id: '17',
            heading: 'B2B commerce platform Solv helps MSMEs digitize supply chains and grow sustainably',
            subHeading: ``,
            image: 'yourStory',
            publication: 'Your Story logo',
            showImage: true,
            url: 'https://yourstory.com/smbstory/solv-msme-ecommerce-platform-b2b-supply-chain',
            postedDate: 'Dec 18, 2020'
        },
        {
            id: '18',
            heading: 'How can India build globally competitive MSMEs?',
            subHeading: ``,
            image: 'ET',
            publication: 'Economic Times Logo',
            showImage: true,
            url: 'https://economictimes.indiatimes.com/small-biz/sme-sector/how-can-india-build-globally-competitive-msmes/articleshow/79835123.cms?utm_source=contentofinterest&utm_medium=text&utm_campaign=cppst',
            postedDate: 'Dec 21, 2020'
        }
        // Below is for reference
        // {
        //     id: '2',
        //     heading: 'How FinTech',
        //     subHeading: `Solv explains the opportunity for B2B eCommerce and integrated on-demand finance…`,
        //     image: '',
        //     publication: '',
        //     showImage: true,
        //     url: '',
        //     postedDate: 'TNN | Mar 25, 2020, 04:00 IST'
        // }

    ]
    return (
        <div className='solv-in-news-container'>
            {/* <div className='solv-news-container-front'></div> */}
            <div className='solv-in-news-title-div'>
                <span className='solv-in-news-title'>Solv in the news</span>
            </div>
            <DragScroll width='100%' height='max-content'>
                <div className='solv-card-holder'>
                    {cardPosts.map((post) => (
                        <CommonCard key={post.id} post={post} />
                    ))}
                </div>
            </DragScroll>
        </div>
    )
}

export default SolvInNews