import React from "react"

import logo from "../../../assets/solvLogo.svg"
import "./TermsOfServices.scss"

const TermsOfServices = () => {
  return (
    <>
      <div className="main-content">
        <div className="title">Terms Of Services</div>
        <p>
          This is an electronic contract in terms of Information Technology Act,
          2000 and rules, there under, as applicable. This electronic contract
          is generated by a computer system and does not require any physical or
          digital signatures. This document is published in accordance with the
          provisions of Rule 3 (1) of the Information Technology (Intermediaries
          guidelines) Rules, 2011 that require publishing the rules and
          regulations, Privacy Policy and Terms for access or usage of the
          Website.
        </p>
        <p>
          This Platform (defined below) including www.solvezy.com and Mobile
          Application is managed and operated by Standard Chartered Research and
          Technology India Private Limited, a company incorporated under the
          Companies Act, 2013 having its registered office and headquarters at
          2nd Floor, Indiqube Edge, Khata No.571/630/6/4, Ambalipura Village,
          Varthur Hobli, Mahadevpura Div, Bangalore 560102, Karnataka, India{" "}
          <strong>(SCR&T)</strong>. SCR&T also has a branch office at Floor 26, Tower C Advant Navis Business Park NOIDA, UTTAR PRADESH 201305 India and services Bangalore, Delhi-NCR, Hyderabad, and Chennai regions.
        </p>
        <p>
          “We”, “our,” and “us” refer to SCR&T. “You” and “your” refer to the
          User and/ or his/her/its Authorized Representative (defined below), as
          applicable.
        </p>
        <p>
          The SCR&T Services are made available solely for business entities
          based in India. By accessing the Website or using the SCR&T Services
          (defined below), the User agrees that the User and the Authorized
          Representative have read and understood, and, agrees to be bound by
          these Terms of Service <strong>(Terms)</strong>, our Privacy Policy at{" "}
          <a
            className="anchor-link"
            href="https://www.solvezy.com/privacy-policy/"
          >
            https://www.solvezy.com/privacy-policy/
          </a>{" "}
          and any applicable Third Party Terms (defined below) (together, this{" "}
          <strong>Agreement</strong>).{" "}
          <strong>
            It is important to read the entire Agreement carefully as some of
            the provisions affect your legal rights and obligations
          </strong>
          .
        </p>
        <p>
          Further, if you are accessing the SCR&T Services through the Mobile
          Application, then while these Terms shall apply to such use, there may
          be additional terms (such as the terms imposed by App Store (iOS),
          Android's (Google) Play Store, Microsoft's store, from time to time
          which will govern the use of Mobile Application. These additional
          terms to the extent applicable are hereby deemed to be incorporated in
          these Terms by way of reference.
        </p>
        <p>
          You may not use the SCR&T Services if you do not accept the terms of
          this Agreement. If you do not agree to be bound by these Terms and the
          Privacy Policy, you should not use the Website. It is strongly
          recommended for you to return to this page periodically and review the
          most current version of the Terms in force. SCR&T reserves the right
          at any time, at its sole discretion, to change or otherwise modify the
          Terms without prior notice, and your continued access or use of this
          Website signifies your assent/ratification of the updated or modified
          Terms. If you object to these Terms or any subsequent modifications to
          these Terms in any way, your only recourse is to immediately terminate
          use of the Website. We may require you to agree to additional terms
          (if any) between you and SCR&T in connection with specific services
          that you may avail from us from time to time.
        </p>
        <p className="sub-heading">1. Definitions</p>
        <p>
          <b>Account</b> shall mean the account created by the User on the
          Website after successful completion of registration.
        </p>
        <p>
          <b>Agreement</b> shall have the meaning as assigned to such term in
          the foregoing paragraph.
        </p>
        <p>
          <b>Authorised Representative</b> shall mean the employee or authorised
          representative of the User who is specifically authorized to register
          on the Website on behalf of the User to assist the User to avail the
          Commerce Offering or Third Party Service Offerings.
        </p>
        
        <p>
          <b>Card Services</b> shall mean credit cards issued by Lenders and
          pre-paid cards issued by Third Party Service Providers authorised by
          the RBI in this regard, offered to Users on the Platform and
          co-branded with SCR&T branding.
        </p>
        <p>
          <b>CIC</b> shall mean a credit information company that has been
          granted a certificate of registration under the Credit Information
          Companies (Regulation) Act, 2005.
        </p>
        <p>
          <b>Commerce Offering</b> shall mean and include the e-commerce portal
          of the SCR&T Services through which Users may offer goods and/or
          services to other Users on the Platform.
        </p>
        <p>
          <b>Content</b> shall have its meaning as provided under clause 6.2.
        </p>
        <p>
          <b>Credit Information</b> shall mean collectively the credit
          information as defined under the Credit Information Companies
          (Regulation) Act, 2005, credit information report, credit score, and
          other credit data and analysis reports received in relation to your
          credit information from CICs.
        </p>
        <p>
          <b>Financial Services</b> shall mean SCF Services, Solv Pay Later and such other services provided by Lenders to the Users on the Platform.
        </p>
        <p>
          <b>Insurance Service</b> shall mean transit insurance, life insurance,
          personal accident coverage etc offered by Third Party Service
          Providers to Users through the Platform, by way of which the Users can
          indemnify themselves against financial losses while availing Third
          Party Services or Commerce Offerings on the Platform.
        </p>
        <p>
          <b>Lender</b> shall mean bank, NBFC or similar financial institution
          onboarded on the Platform as Third Party Service Providers to provide
          Financial Services and/or Card Services.
        </p>
        <p>
          <b>Logistics Services</b> shall mean services in relation to
          logistics, deliveries, shipments, packers and movers provided by Third
          Party Service Providers.
        </p>
        <p>
          <b>Mobile Application</b> shall mean the mobile application belonging
          to SCR&T which is developed and designed to run on various mobile
          operating systems, for providing the SCR&T Services through the
          Platform.
        </p>
        <p>
          <b>Organisation</b> shall mean a sole proprietorship firm, partnership
          firm, a company registered under the Companies Act, 2013 or erstwhile
          Companies Act, 1956, or an LLP registered under Limited Liability
          Partnership Act, 2008 based in India.
        </p>
        <p>
          <b>OVD </b> shall mean passport, driving licence, Aadhaar card,
          voter's identity card, job card issued by NREGA, or letter issued by
          the National Population Register containing details of name and
          address, or any other officially valid document as per the RBI Master
          Direction - Know Your Customer (KYC) Directions, 2016, as amended from
          time to time.
        </p>
        <p>
          <b>Partner</b> shall mean third party partners of SCR&T from whom
          SCR&T may obtain certain services in relation to the Website including
          but not limited to the CICs, verification companies, information
          repositories etc. to provide the SCR&T Services.
        </p>
        <p>
          <b>Platform</b> shall mean digital platform having the tradename and
          brand name ‘Solv’, as operated and maintained by SCR&T, the front-end
          version of which is accessible through the Website.
        </p>
        <p>
          <b>Privacy Policy</b> shall mean the SCR&T privacy policy as provided
          at{" "}
          <a
            className="anchor-link"
            href="https://www.solvezy.com/privacy-policy"
          >
            https://www.solvezy.com/privacy-policy
          </a>
        </p>
        <p>
          <b>Party</b> shall mean, in relation to SCR&T, its representatives,
          agents, and any of their respective officers, directors, equity
          holders, employees, parent entities, subsidiaries, affiliated
          entities, representatives, agents and licensors.
        </p>
        <p>
          <b>SCF Services</b> shall mean the supply chain financing services
          provided by the Lenders to the Users on the Platform.
        </p>
        <p>
          <b> SCR&T Services</b> shall mean the service of providing the Website
          and the Platform which facilitates and connects Users to (i) other
          Users, and (ii) various Third Party Service Providers providing
          Financial Services, logistics, accounting, HR and payroll management
          services etc. to the User, and any related services in relation to the
          Commerce Offering or Third Party Service Offerings, which may include
          'text to speech' and speech recognition services.
        </p>
        <p>
          <b>Third Party Service Offerings</b> shall mean the Financial
          Services, Card Services, Insurance Services, Logistics Services, HR,
          accounting and other services provided by Third Party Service
          Providers to Users on the Website.
        </p>
        <p>
          <b> Third Party Service Providers</b> shall mean service providers
          including Lenders, prepaid card issuers, sellers, service providers,
          insurers and logistics providers who are onboarded on the Platform and
          from whom the Users can avail the Third Party Service Offerings.
        </p>
        <p>
          <b>Third Party Terms</b> shall mean the additional terms and
          conditions that the User may have to agree separately with the Third
          Party Service Providers to avail any Third Party Service Offerings.
        </p>
        <p>
          <b>User</b> shall mean any Authorized Representative/Organisation (as
          the case maybe) registered on the Website being a business entity in
          India for using the SCR&T Services to avail any Commerce Offering or
          Third Party Service Offerings and shall include master anchors,
          anchors and dealers under the SCF Services.
        </p>
        <p>
          <b>User Consent</b> shall mean prior consent procured from the User
          (by means of a click-wrap contract in the form prescribed in either
          Annexure-1 for individual Users or Annexure-2 for Organisations, as
          applicable) in relation to access to information by SCR&T on behalf of
          the User from a Partner such as from the CIC or Goods and Services Tax
          Network or Ministry of Company Affairs and such other government or
          non-government networks.
        </p>
        <p>
          <b>User Data</b> shall mean any data submitted by User or the
          Authorized Representative on behalf of the User, to or in relation to
          the SCR&T Services or Commerce Offerings or Third Party Service
          Offerings, including Personal Information and Sensitive Personal
          Information, as mentioned in the Privacy Policy.
        </p>
        <p>
          <b>User Verification</b> shall mean the process followed by SCR&T to
          ensure eligibility of the User to avail any Third Party Service
          Offerings.
        </p>
        <p>
          <b>Website</b> shall mean the domain i.e. www.solvezy.com and/or the
          Mobile Application.
        </p>
        <p>
          <b>SCR&T</b> and the <b>User</b> shall as the context so requires, be
          individually referred to as a <strong>“Party”</strong> and
          collectively as the <strong>“Parties”</strong>.
        </p>
        <p className="sub-heading">2. Registration and Usage of Website</p>
        <p>
          <span className="sub-heading">2.1</span> The Website is freely
          accessible currently, however, prior to availing any of the SCR&T
          Service(s) as a User, an organization/ individual will have to
          register on the Website and create an Account. The Authorized
          Representative can register the User using a valid mobile number and
          corporate email address and accept the Terms and the Privacy Policy on
          behalf of the User. A "one time password" (<b>OTP</b>) will be sent to
          the mobile number of the Authorized Representative and a verification
          email will be sent to the valid email address provided by the
          Authorized Representative. You will need to (i) enter the OTP for
          validating your mobile number; and (ii) verify the email for
          validating your email address, to activate and access your Account.
          Once activated, you can use your mobile number together with your
          password to access the SCR&T Services (subject to additional
          conditions to access Commerce Offerings or specific Third Party
          Service Offerings as may be specified hereunder) and it will remain
          active until terminated in accordance to these Terms. An Account
          created by an Authorized Representative without providing necessary
          support documentation, such as board resolution or resolution of
          partners etc, as may be required by SCR&T, for appropriate
          authorization from the User will be suspended and terminated.
        </p>
        <p>
          <span className="sub-heading">2.2</span> You will also be required to
          submit certain documents stipulated as per SCR&T’s policies as
          applicable from time to time, for User Verification such as PAN, GST
          number, directors/ partner KYC documents (e.g. Aadhaar Card, driver
          license or other government issued identity proof), bank statements of
          specific duration, board resolution, income statement, sales and
          purchase data, cashflow statement, balance sheet, Income Tax return
          filing data, photos and videos, etc. Please refer to Annexure A and
          Annexure B of this Agreement for further details on the documents that
          we may collect. We may also collect your net banking credentials, bank
          statements, etc. for the purpose of providing you the Financial
          Services and Commerce Offering with your specific consent.
        </p>
        <p>
          <span className="sub-heading">2.3</span> You hereby expressly authorise SCR&T to use such documents, store and process such information for the purposes of this Agreement. To the extent permitted under applicable laws, we will also fetch documents and information pertaining to you in relation to the goods and service taxes, income tax reports, etc. from the records maintained by Ministry of Corporate Affairs, Income Tax department and other governmental authorities as part of the User Verification. Further details of the information / documents that we collect and the manner in which we use the information/ documents are detailed in our Privacy Policy available on our Website, which forms part of these Terms and shall stand incorporated therein. If these Terms read with our Privacy Policy are not acceptable to you, please do not provide any of your information to SCR&T. However, in such event, we may not be able to provide you with the SCR&T Services or some parts of it, as may be applicable. We provide our Third Party Service Providers including Lenders (as stated herein) your User Data to enable you to avail any Third Party Service Offering including Financial Services. User Data shared by Users may also be stored and shared with Partners/third party technology vendors for providing services such as credit risk evaluation/data analysis and other similar services. Such collection, storage, use and transfer of User Data shall be governed in accordance with the Privacy Policy. Kindly refer the Privacy Policy for more details.
        </p>
        <p>
          <span className="sub-heading">2.4</span> The details and/or documents
          requested on the Website are not an exhaustive list of requirements
          and SCR&T reserves the right to requisition and/or extract additional
          documents and / or details as may be deemed necessary by SCR&T in its
          sole discretion, to complete User Verification or KYC. In the event
          the details or documents submitted for creating Account, or for
          availing the SCR&T Services, are incomplete or discrepant, SCR&T will
          request additional information / documentation through email / SMS
          sent to the email ID / mobile number as provided by you when you
          sought to register on the Website. In case the discrepancies remain
          unresolved or if SCR&T is unable to successfully complete its User
          Verification in accordance with its policies, SCR&T shall have the
          right to reject your request for registering with the Website and/or
          availing the Third Party Service Offerings. SCR&T reserves the
          absolute discretion to accept or reject your request for creating the
          Account towards availing SCR&T Services, Commerce Offerings and any
          Third Party Service Offerings, without assigning any reasons for the
          same.
        </p>
        <p>
          <span className="sub-heading">2.5 </span> By registering and creating
          an Account with SCR&T, the User certifies that:
        </p>
        <p>
          <span className="sub-heading">(a)</span> The User/Authorized
          Representative have created the Account for your own personal/ your
          Organisation's use and that the information and documents you provide
          to SCR&T will be your/ your Organisation's details (and not that of
          any third person or entity);
        </p>
        <p>
          <span className="sub-heading">(b)</span> All information and documents
          that you provide to SCR&T at any time, including the during the course
          of any commercial transaction with other Users, are true, complete and
          accurate and any such information is supported by genuine and valid
          documents submitted to SCR&T. You further confirm that you will keep
          all such information and documents current, complete, accurate and
          valid at all times for as long as your Account remains valid. You can
          log in to your Account to review and modify any changed details. You
          may be required to submit documentary proof as required by SCR&T’s
          policies from time to time to authenticate the modified details and
          may not be able to use the SCR&T Services until verification of the
          changed details is successfully completed by SCR&T. You hereby agree
          that you will indemnify SCR&T for any loss, damage, claim, action,
          costs, charges and expenses which SCR&T may suffer or incur as a
          result of acting on, or relying on the information and documents
          provided by you;
        </p>
        <p>
          <span className="sub-heading">(c)</span> The Authorized Representative
          has attained majority age, is of employable age and has been duly
          authorized to act on behalf of the User for the purpose of using the
          SCR&T Services;
        </p>
        <p>
          <span className="sub-heading">(d)</span> You will be responsible for
          safeguarding the security and confidentiality of your Account and
          password and you will not share, assign or transfer your Account or
          reveal your password to any third party or do anything else that might
          jeopardize the security of your Account; and that you will be solely
          responsible and liable for all actions/instructions initiated using
          your Account. You may be held liable for losses incurred by SCR&T or
          any other User due to authorized or unauthorized use of your Account
          as a result of your failure in keeping your Account information secure
          and confidential and/or not promptly notifying SCR&T in the event of
          any breach of security;
        </p>
        <p>
          <span className="sub-heading">(e)</span> You will not create more than
          one Account for the same entity. Provided however that you can invite
          members of your organization as team members for accessing the same
          account in the manner elaborated in the Platform;
        </p>
        <p>
          <span className="sub-heading">(f)</span> You will comply at all times
          with all anti-corruption, anti-slavery, anti-bribery and anti-child
          labour laws and shall not engage in practices involving child labour
          or slavery;
        </p>
        <p>
          <span className="sub-heading"> (g) </span>SCR&T may suspend or
          terminate your Account or your use of the SCR&T Services at any time
          without notice, if we suspect that any information / documents/
          authorization that you have provided are untrue, inaccurate, not
          current, or incomplete or if you are found to be in breach of any of
          these Terms.
        </p>
        <p>
          <span className="sub-heading">(h) </span>You will use the Platform for
          availing goods or services solely for resale or commercial purposes.
          You will not be buying or using the goods or services exclusively for
          the purpose of earning your livelihood, by means of self-employment.
        </p>
        <p>
          <span className="sub-heading">2.6 </span>In the event the User is
          required to provide any security or collateral (in the form of
          immovable or movable asset) in relation to a Third Party Service
          Offering, the User shall provide true, complete and accurate
          information pertaining to such asset on the Website or in physical
          copy. Further, the User shall be required to furnish the title
          documents, either online or in physical copy, pertaining to such
          asset, as and when required by the relevant Third Party Service
          Provider to demonstrate that the User has the sole right, title and
          interest to such asset.
        </p>
        <p className="sub-heading">3. SCOPE OF SERVICES AND LIMITATIONS</p>
        <p>
          <span className="sub-heading">3.1</span>
          &nbsp;The SCR&T Services provided by SCR&T are available and
          appropriate only for use in India. The Website is merely an online
          platform and SCR&T only acts as an intermediary to enable Users and
          the Third Party Service Providers to interact with each other for
          their transactions and for availing the Third Party Service Offerings
          provided herein. You hereby agree and accept that SCR&T is not and
          shall not be a party to any agreements entered into or executed
          between the Users and Third Party Service Providers. Further, you
          agree that SCR&T has no control over the conduct of any Third Party
          Service Providers or their Third Party Service Offerings and disclaims
          any and all liability in this regard to the maximum extent permitted
          by applicable law.
        </p>
        <p>
          <span className="sub-heading">3.2</span>
          &nbsp;You may be required to accept and agree to be bound by Third Party Terms for availing the specific Third Party Service Offerings you choose. The applicable Third Party Terms may be set out at the point of access to the relevant Third Party Service Offerings. These Third Party Terms are in addition to, and not in derogation of these Terms.
        </p>
        <p>
          <span className="sub-heading">3.3</span>
          &nbsp;For details of the various Third Party Service Offerings, the
          scope of such service provided, the term of the relevant Third Party
          Service Offering and the corresponding charges applicable, please
          refer to the relevant Third Party Terms.
        </p>
        <p>
          <span className="sub-heading">3.4</span>
          &nbsp;There may be certain Partner terms and privacy policies which
          may be applicable to the SCR&T Services, which may be appended to
          these Terms, set out at the point of access to the relevant Partner
          portals or referred at such other appropriate place. You are also
          responsible for reading them and complying with them. Any data that
          you submit to such Partners shall be governed by the Partner privacy
          policies. SCR&T has no control over the conduct of any Partners and
          their use of data and disclaims any and all liability in this regard
          to the maximum extent permitted by applicable law.
        </p>
        <p>
          <span className="sub-heading">3.5</span>
          &nbsp;All transactions undertaken on your behalf by SCR&T will be on
          the basis of your express instructions/prior approval and will be
          strictly on a non-discretionary basis. SCR&T only facilitates the
          availing of Third Party Service Offerings from the Third Party Service
          Providers or Commerce Offerings from other Users. You fully understand
          and agree that the final decision of providing the Third Party Service
          Offering or Commerce Offering will rest exclusively with the Third
          Party Service Provider or the User concerned, respectively and the
          Third Party Service Provider or the User concerned is not obliged to
          accept any advice/recommendations provided by SCR&T. SCR&T is not
          responsible for any non-performance or breach of any contract entered
          into between Third Party Service Provider and Users and any
          transaction between two or more Users on the Platform. SCR&T cannot
          and does not guarantee that the concerned Third Party Service Provider
          shall perform any transaction pursuant to the usage of Website. SCR&T
          shall not and is not required to mediate or resolve any dispute or
          disagreement between Third Party Service Provider and the Users or
          inter-se between the Users. Subject to applicable laws, SCR&T shall
          also have the right, but not the obligation, to provide such
          information and documents, as reasonably required by any User in the
          course of its dispute or disagreement with a Third Party Service
          Provider or any other User.
        </p>
        <p>
          <span className="sub-heading">3.6</span>
          &nbsp; By accepting these Terms, you hereby request and expressly
          authorize SCR&T and its employees or agents to communicate with you,
          and accept instructions from you or with your approval, (i) over the
          telephone by SMS or calls (including robo calls and IVR calls) at the
          number you provide to SCR&T (ii) by email at the email address you
          provide to SCR&T; (iii) over video calls generated on the Platform
          (iv) in writing (including through documents uploaded by you through
          your Account or otherwise); (v) through 'click to accept' contracts
          and forms, and/or any other mode and form as may be determined
          appropriate by SCR&T from time to time. You hereby consent to SCR&T,
          at its sole discretion, recording all telephone calls/ video calls
          between you and SCR&T representative for any purpose whatsoever,
          however subject to applicable laws, and any such records of SCR&T
          shall be conclusive and binding evidence of the fact and content of
          such calls. It is your responsibility to intimate SCR&T of any change
          in your email address or telephone number. You hereby confirm that
          instructions / approval so provided by you will be valid, effective
          and binding on you without any further enquiry or due diligence as to
          identity of the person purporting to give such instruction / approval.
          SCR&T may, in its sole discretion, seek further confirmation of any
          instruction / approval given or purporting to originate from you. You
          hereby specifically consent that SCR&T shall be entitled to use any
          User Data or data/information SCR&T derives from your transaction or
          usage patterns on the Platform for any cross selling of service offerings or
          promotion of new offerings in the manner determined by SCR&T.
        </p>
        <p>
          <span className="sub-heading">3.7</span>
          &nbsp;For as long as your Account remains active, SCR&T may, from time
          to time, recommend SCR&T Services or Third Party Service Offerings. We
          shall not be held liable or responsible for any recommendation we
          provide regarding any Third Party Service Provider or Third Party
          Service Offering. Any such recommendation is not an endorsement,
          certification or guarantee by SCR&T about any Third Party Service
          Provider and is only intended to be useful information for you to make
          an informed decision. The ultimate decision regarding the
          appropriateness of any Third Party Service Offering is exclusively
          yours and you should exercise due caution and/or seek independent
          advice/due diligence before entering into any financial decision.
          SCR&T shall not be a party to and shall not be responsible or liable
          for any transactions, interactions, conduct or communications between
          you and Third Party Service Provider, on or through the Website or
          otherwise. SCR&T may receive a facilitation fee from the Third Party
          Service Providers and any other third party banks / credit
          institutions and you hereby confirm that you have no objection to the
          same.
        </p>
        <p>
          <span className="sub-heading">3.8</span>
          &nbsp;SCR&T may provide 'text to speech' and 'speech recognition'
          facilities to Users of the Website and will record all chats and
          conversions occurring on the Website for this purpose. We may share
          these records with our Partners to enable these facilities. You hereby
          provide your consent for SCR&T to record and share your chats and
          conversations on the Website with Partners in accordance with these
          Terms.
        </p>
        <p>
          <span className="sub-heading">3.9</span>
          &nbsp;SCR&T may at its discretion introduce any new SCR&T Services and
          Third Party Service Offerings.
        </p>
        <p>
          <span className="sub-heading">3.10</span>
          &nbsp;SCR&T shall not be liable for any failure or delay to perform
          any of its obligations if such performance is prevented, hindered or
          delayed, in part or entirely, by any event beyond the reasonable
          control of SCR&T, including without limitation, fire, flood,
          explosion, acts of god, terrorist acts, civil commotion, strikes or
          industrial action of any kind, riots, insurrection, war, acts of
          government, acts of third parties beyond SCR&T’s reasonable control,
          security breaches, power shut down, faults or failures in
          telecommunication or internet services or unauthorized access to
          computer systems <strong>(“Force Majeure Event”)</strong>.
        </p>
        <p>
          <span className="sub-heading">3.11</span>
          &nbsp;For providing any of the SCR&T Services or Third Party Services,
          SCR&T may be required to ascertain your location or geolocation. You
          hereby provide your consent for SCR&T and Partners to ascertain,
          track, record and share your location and geolocation in accordance
          with applicable laws.
        </p>

        <p className="sub-heading">
          4. SPECIFIC TERMS FOR THIRD PARTY SERVICES
        </p>
        <p className="sub-heading">4.1 Financial Services</p>
        <p className="sub-heading">A. Loan Service</p>
        <p>
          <b>(a)</b> When you apply for or avail any Financial Services or Card
          Services, we may require you to submit certain documents in accordance
          with <strong>Annexure A</strong> to verify your Organisation and the
          Authorised Representative. We may also require you to update your KYC
          documents, as and when required. We may also require you to provide us
          with your net banking details and/or give us access to your bank
          account statements. We will also provide you with an option for
          offline verification to verify the identity and address of your
          Authorised Representative. If you select this option, you may submit
          the QR code verification or the XML document downloaded from the
          portal of the Unique Identification Authority of India, in the manner
          prescribed under applicable laws. We may receive the personal
          information of the Authorised Representative such as name, gender,
          date of birth, address, phone number etc., pursuant to such KYC
          verification and you hereby consent to the same. We may also undertake
          video KYC verification, if permitted by the RBI. We may also require
          you to submit any other document as may be required by the relevant
          Third Party Service Provider from time to time.
        </p>
        <p>
          <b>(b)</b> The Financial Services or Card Services provided to you may
          be based on your Credit Information, which is maintained by various
          authorized CICs in India and which we obtain from such CICs on your
          behalf with User Consent and information derived by using SCR&T's
          proprietary credit analysis algorithms, and any other financial
          information that you may choose to provide to SCR&T. As an additional
          step you may be directed to the CIC's website to provide
          authorization/ consent for sharing your Credit Information by the CIC
          with SCR&T on your behalf. If the information and documents provided
          to SCR&T (by the concerned CICs or you) are incorrect or incomplete,
          it will adversely affect the quality of the SCR&T Services, including
          any recommendation provided by us regarding the Third Party Service
          Providers.
        </p>
        <p className="sub-heading">
          B. Services for Supply Chain Financing (SCF)
        </p>
        <p>
          <b>(a)</b> For the purposes of this clause, "you" shall include master
          anchors, anchors and dealers registered on the Platform.SCR&T is
          offering the SCF program through the following Lenders who shall be
          its financing partners: (i) the lending partners of{" "}
          <strong> Indifi Technologies Private Limited</strong>, a company
          incorporated under the Companies Act, 2013 and having its registered
          office at C 902, Park View Spa, Sector 47, Gurgaon – 122001 and as set
          out at{" "}
          <a
            className="anchor-link"
            href="https://www.indifi.com/lending-partners"
          >
            https://www.indifi.com/lending-partners
          </a>
          ; (ii) <strong>Axis Bank Limited</strong>, a banking company under the
          Banking Regulation Act, 1949 and a company under the Companies Act,
          2013 having its registered office at ‘Trishul’, 3rd Floor, Opposite
          Samartheshwar Temple, Law Garden, Ellis Bridge, Ahmedabad 380 006,
          Gujarat; (iii){" "}<strong>Adani Capital Private Limited</strong>, a company incorporated
          under the Companies Act 2013, having its registered office at Adani
          House, 56 Shrimali Society, Navrangpura, Ahmedabad – 380009; and (iv){" "}
          <strong>Hero Fincorp Limited</strong>, a company incorporated under
          the Companies Act, 1956 and registered as a non-banking financial
          company under the provisions of Reserve Bank of India and having its
          registered office at 34, Community Centre, Basant Lok, Vasant Vihar,
          New Delhi – 110057 (v){" "}<strong>Cholamandalam Investment and Finance Company Limited</strong>,a non-banking financial company incorporated under the laws of India and is registered as a non-banking financial company under the provisions of Reserve Bank of India and having its registered office at ‘Dare House’, No. 2, N.S.C., Bose Road, Parrys, Chennai – 600001;
          (vi){" "}<strong>Mintifi Finserve Private Limited</strong>, a non-banking financial company registered under the provisions of Reserve Bank of India, and having its registered office at Unit No. 3B, 2nd Floor, Times Square Andheri Kurla Road, Marol, Andheri (East), Mumbai- 400059; 
          (vii)<strong>Tata Capital Financial Services Ltd.</strong>, registered as a non-banking financial company under the provisions of Reserve Bank of India  and having its registered office Tower A, 11th Floor, Peninsula Business Park, Ganpatrao Kadam Marg, Lower Parel, Mumbai 400 013;
          (viii)<strong>UGRO Capital Limited</strong> , registered as non-banking financial company under the provisions of Reserve Bank of India and having its registered office at Equinox Business Park, Tower 3, 4 th floor, LBS Road, Kurla, Mumbai – 400 070.
        </p>
        <p>
          <b>(b)</b> When anchor or dealer applies for or avail any SCF Services
          on our Platform, we may require submission of certain documents in
          accordance with <strong>Annexure B</strong> to verify the Organisation
          and the owner. We may also require the dealer and anchor as well as
          their owner to submit KYC documents, in accordance with{" "}
          <strong>Annexure A</strong>. We may also require anchor/dealer to
          provide net banking details and/or give us access to their bank
          account statements. We may receive the personal information of the
          owner such as name, gender, date of birth, address, phone number etc.,
          pursuant to such KYC verification and you hereby consent to the same.
        </p>
        <p>
          <b>(c)</b> As a part of the SCF Service provided, SCR&T may collect
          Credit Information of the anchor/dealer or their owners, which is
          maintained by various authorized CICs in India and which we obtain
          from such CICs on their behalf with User Consent. As an additional
          step they may be directed to upload registered mobile number for the
          Credit Information and accept the terms and conditions.
        </p>
        <p>
          <b>(d)</b> The participation of a dealer in the SCF program shall be
          conditional on the recommendation provided by the anchor. The dealer
          should not be part of any other supply chain program(s) being
          supported by anchor. The drawdown for a dealer shall be based on list
          of invoices raised by anchor of the dealer.
        </p>
        <p>
          <b>(e)</b> The anchor uploads invoices in SCF portal which will
          reflect against corresponding dealer. All invoices shall then be
          shared with the relevant Lender as determined by SCR&T for financing.
          In the event of Lender approving the financing, which may be done at
          its sole discretion, the Lender shall disburse the loan amount to
          anchor’s bank account.
        </p>
        <p>
          <b>(g)</b> The dealer shall be entitled to avail the financing
          facility under the SCF program solely for financing the dealer’s
          purchase of products from the relevant anchor as approved by master
          anchor (if any) and not of any other entity.
        </p>
        <p>
          <b>(h)</b> Upon the Lender approving the facility, the Lenders and
          dealer shall enter into financing facility agreements to capture the
          terms of grant of financing facility and the dealer shall repay the
          amounts in accordance with the financing facility agreement directly
          to the Lender. The dealer shall execute appropriate documents as
          required by SCR&T for enabling such flow of funds.
        </p>
        <p>
          <b>(i)</b> The anchor shall also share with SCR&T all other relevant
          information pertaining to the dealer for ascertaining the grant of
          financial facility, as permitted by Applicable Laws. SCR&T shall be
          entitled to share such information with the Lenders on the Platform
          for the purpose of granting financing facility and providing Platform
          Services and in connection therewith, may use, retain, process,
          analyse, reproduce, transmit, distribute, create derivative works out
          of the dealer information subject to the Applicable Laws.
        </p>
        <p>
          <b>(j)</b> The provision of the facility to the dealer is at the
          discretion of Lender, and the Lender reserves the right to reject
          (exercisable in its discretion) the facility to a dealer, with or
          without any notice or reason and without any liability. In the event
          the lender rejects the grant of any facility to the dealer, the dealer
          shall be required to immediately make the relevant payment to the
          anchor. The anchor shall directly pursue with the dealer to recover
          any outstanding payment from the dealer. In the event of rejection of
          grant of any facility by a Lender or default of facility by dealer for
          any reason whatsoever, SCR&T shall not be liable to make any payment
          to the anchor, regardless of whether the goods and/or services have
          been provided to the dealer or not.
        </p>
        <p>
          <b>(k)</b> SCR&T may disclose to any Person, in part or whole of the
          dealer information (or any modification thereof) (i) if it is required
          or permitted to do so by law, any governmental authority, any court
          order, decree or award; (ii) in order to protect against or prevent
          actual or potential fraud or unauthorized transactions; (iii) in order
          to investigate any fraud that has already taken place; or (iv) if it
          is required to do so under any agreement entered into by SCR&T with
          any Lenders.
        </p>

        <p>
          <span className="sub-heading">C. Pay Later Program</span>
        </p>
        <p>
          <b>(a)</b>SCR&T may offer a Pay Later program in collaboration with
          Lenders from time to time to certain identified Users, at its
          discretion. These terms and conditions (<b>“Terms”</b>) are applicable
          between you and SCR&T for the transactions for purchase of goods or
          services using the "Solv Pay Later" payment method (
          <b>"Solv Pay Later"</b>) on the Platform.
        </p>
        <p>
          <b>(b)</b> SCR&T is offering the current Pay Later program through the
          following Lenders who shall be its financing partners (i) <b>Arthashastra Fintech Private Limited</b>, a company
          incorporated under the Companies Act, 2013 and having its registered
          office at 94, 3G, Kalpataru Aura, LBS Marg, Ghatkopar - West, Mumbai,
          Maharashtra - 400086; (ii) the lending partners of{" "}
          <b>Rupifi Technology Solutions Private Limited</b>, a company
          incorporated under the Companies Act, 2013 and having its registered
          office at N-138, LGF, Panchsheel Park, New Delhi – 110017 and as set
          out at{" "}
          <a className="anchor-link" href="https://www.rupifi.com/">
            https://www.rupifi.com/
          </a>{" "}
          ;(iii) <b>Mintifi Finserve Private Limited</b>, a non-banking financial company
          registered under the provisions of Reserve Bank of India, and having
          its registered office at Unit No. 3B, 2nd Floor, Times Square Andheri
          Kurla Road, Marol, Andheri (East), Mumbai- 400059; (iv) <b>Muthoot FinCorp Limited</b>, a non-banking financial company registered under the provisions of Reserve Bank of India, having its registered office at Muthoot Centre, TC No 27/ 3022, Punnen Road, Thiruvananthapuram, Kerala – 695001.
        </p>
        <p>
          <b>(c)</b> It is clarified that these Terms are applicable only if
          SCR&T makes the Solv Pay Later program available to buyers (
          <b>“Buyers”</b>) for payment to sellers (<b>“Sellers”</b>) on the
          Platform. The expressions “User” and “You” shall refer to both Seller
          and Buyer.
        </p>
        <p>
          <b>(d)</b> The following terms & conditions apply to any and all
          transactions made on Platform using the Solv Pay Later payment
          option:-
        </p>
        <p>
          (i) Solv Pay Later is a payment option available to certain select
          Users who are Buyers to make payments to Sellers on the Platform. Solv
          Pay Later option may only be available for purchase of select goods on
          the Platform.
        </p>
        <p>
          (ii) If a Buyer chooses to paying using Solv Pay Later, the Buyer will
          transact with one of our Lenders mentioned above. Under Solv Pay
          Later, the Lender will provide a credit facility to the Buyer to make
          purchases on the Platform and make payments for the same on a deferred
          basis (<b>"Facility"</b>).
        </p>
        <p>
          (iii) Under the Facility, in the event the Buyer is purchasing
          products from the apparel, home furnishings, footwear category, the Buyer shall be entitled to
          receive credit facility at zero percentage interest rate for the first
          30 (thirty) days from the date of disbursement of Facility and in the
          event the Buyer is purchasing products from any other category, the
          Buyer shall be entitled to receive credit facility at zero percentage
          interest rate for the first 14 (fourteen) days from the date of
          disbursement of Facility. In the event the Buyer fails to repay the
          Facility within the aforesaid timelines, the Buyer shall be solely
          liable to pay the interest amounts (in addition to the principal
          amounts) towards the Facility at such rates as agreed with the
          Lenders. Not withstanding the above, the above timelines and product category may vary depending on the Lender who is extending the credit facility and the product being purchased.
        </p>
        <p>
          (iv) The provision of the Facility is at the discretion of Lender, and
          the Lender reserves the right to reject (exercisable in its
          discretion) the Facility to a Buyer, with or without any notice or
          reason and without any liability. In the event the Lender rejects the
          grant of any Facility to the Buyer, the Buyer shall be required to
          immediately make the relevant payment directly to the seller or
          through SCR&T, in the manner elaborated under Clause 5.13 below. The
          seller shall directly pursue with the buyer to recover any outstanding
          payment from the buyer. In the event of rejection of grant of any
          Facility by a Lender or default of Facility by buyer for any reason
          whatsoever, SCR&T shall not be liable to make any payment to the
          seller, regardless of whether the goods and/or services have been
          availed by the buyer or not.
        </p>
        <p>
          (v) The Buyers and Sellers hereby expressly consent and agree that the
          Lenders shall disburse the loan amounts into the bank account of
          SCR&T. SCR&T will thereafter transfer the loan amounts into the
          specified bank account of the Seller. The Buyers and Sellers
          acknowledge and confirm that they shall not have any objection in
          SCR&T receiving the loan amounts from the Lenders on behalf of Buyer
          and making the payments to the Seller. The Sellers and Buyers hereby
          expressly authorise SCR&T to receive the disbursed loan amount from
          the Lenders.
        </p>
        <p>
          (vi) SCR&T has no role to play pertaining to the offering of the
          Facility or the terms on which the Facility is provided by the
          Lenders, including but not limited to its issuance, approval,
          extension, pre-closure, repayment or closure of the Facility and such
          matters are solely determined by the Lender.
        </p>
        <p>
          (vii) As a pre-requisite to avail the Facility, the User must have a
          valid SCR&T Platform’s identification number and provided the Merchant
          Information as required by SCR&T to the satisfaction of SCR&T. The
          User must also have a bank account with one of the specified banks in
          India. The Lender may specify the banks for this purpose from time to
          time, which will be listed on the Lender's website.a pre-requisite to
          avail the Facility, the User must have a valid SCR&T Platform’s
          identification number and provided the Merchant Information as
          required by SCR&T to the satisfaction of SCR&T. The User must also
          have a bank account with one of the specified banks in India. The
          Lender may specify the banks for this purpose from time to time, which
          will be listed on the Lender's website.
        </p>
        <p>
          (viii) In order to avail the Facility, the Buyer must enter into a
          loan agreement with the Lender specifying the details of sanction and
          available credit limits (<b>"Loan Agreement"</b>) as an essential
          pre-condition, in the form notified by the Lender and undertake such
          other actions as required by such Lender. You may also be required to
          make a pre-payment to the Lender towards the Facility extended by the
          Lender.
        </p>
        <p>
          (ix) Solv Pay Later can only be used for purchases on the Platform if
          the Buyer has sufficient balance/limit available under the Facility
          granted by Lender. In the event the Buyer chooses the Pay Later option
          for any purchase, however, the balance/limit available under the
          Facility is inadequate, SCR&T reserves the right to cancel such order.
        </p>
        <p>
          (x) The amount of the Facility may be revised and the Facility may be
          cancelled by Lender in its sole discretion, and without assigning any
          reason and without any liability. SCR&T has no role in this regard.
        </p>
        <p>
          (xi) The repayment of the Facility will be made directly by the Buyer
          to the Lender and will be governed by the terms notified by the Lender
          from time to time. The Lenders may make various modes and mediums
          available for repayment of the Facility. SCR&T shall not be liable for
          any default by the Buyer in complying with the terms and conditions of
          the Facility, including the repayment of Facility amount.
        </p>
        <p>
          (xii) Availing the Facility is governed by the terms and conditions
          separately prescribed by the Lender and any other terms that the
          Lender may notify from time to time.
        </p>
        <p>
          (xiii) By using Solv Pay Later, by availing the Facility provided by
          the Lender, each User hereby fully and unconditionally releases and
          completely discharges SCR&T and its affiliates against any claims
          (howsoever arising) in respect of availing of the Facility, and all
          such claims (if any) will lie only against Lender. The User shall
          indemnify SCR&T for any claim, losses or costs arising out of or in
          respect of any breach or default of the terms and conditions of the
          Facility by the User.
        </p>
        <p>
          (xiv) By participating in the Pay Later Program, the User is
          consenting and permitting SCR&T to share with Lender, the data /
          information (so requested by Lender) of such User in possession of
          SCR&T, which may have been collected from the said User by SCR&T in
          respect of the User's KYC for the other services offered on the
          Platform.
        </p>
        <p>
          (xv) The Users also agree and acknowledge that SCR&T and its
          affiliates will not be liable or responsible for any claim on account
          of availability or non-availability of Solv Pay Later facility on the
          Platform.
        </p>
        <p>
          (xvi) SCR&T reserves the right to cease to make Solv Pay Later
          available as a payment option, at any time without prior notice and
          without any liability.
        </p>
        <p>
          (xvii) By using Solv Pay Later by availing the Facility, the User
          acknowledges that SCR&T and its Lender may use the User's data in
          connection with the Facility, including without limitation for
          processing and activation of the Facility. In this regard, the User
          agrees and consents for SCR&T to share the information or data
          pertaining to the User with the Lender.
        </p>
        <p>
          (xviii) The Users agree that SCR&T and its affiliates will not be
          liable for any losses or damages suffered by the Users on account of
          use of Solv Pay Later or the Facility, including as a result of any
          fraud in connection with such use.
        </p>
        <p>
          (xix) If an order for which payment has been made using the Solv Pay
          Later facility is cancelled, any refund to the Users or cancellation
          of the Facility will be undertaken by Lender. The Users hereby agree
          and acknowledge that SCR&T has no role in this regard and that SCR&T
          will not be liable or responsible for any claim on this account.
        </p>
        <p>
          (xx) SCR&T will not be held liable for any dispute arising out of or
          in connection with use of the Solv Pay Later payment option or the
          Facility. In case of any dispute between Seller and Buyer, inter alia
          regarding the quality or quantity of products or any other aspect
          regarding the commercial transaction, neither the Lender nor SCR&T
          will have any liability. Such dispute shall be directly resolved
          between the Seller and Buyer and the same shall not have any effect on
          the Buyer’s repayment obligation to the Lender and the Buyer shall
          continue to be liable to repay the Facility to the Lender.
        </p>
        <p>
          (xxi) You authorize SCR&T and/or its Lender to communicate with you,
          through emails or SMS or notifications on websites / mobile
          applications or any other mode, in connection with use of the Solv Pay
          Later or the Facility. You acknowledge that Lender may communicate
          with you through IVR or telephonically.
        </p>
        <p>
          (xxii) Please contact Lender for any queries in relation to the
          Facility or SCR&T for any queries in relation to availability of Solv
          Pay Later.
        </p>

        <p>
          <span className="sub-heading">D. General</span>
        </p>
        <p>
          SCR&T does not guarantee that your application to avail Financial
          Services or Card Services will be successful. The decision to provide
          you with the Financial Services or Card Services lies with the Third
          Party Service Provider who makes the relevant Third Party Offering
          available to you. Please read the applicable Third Party Terms
          carefully to understand the eligibility criteria and conditions for
          these Third Party Offerings. Prior to providing any facility, the
          Lenders may conduct credit approval checks on you and collect Credit
          Information. The Users hereby expressly authorize and provide consent
          to the Lenders to collect their Credit Information from any CIC and
          conduct the relevant credit checks. In the event you are required to
          enter into a separate agreement with Lenders for availing the
          Financial Services, you shall not agree on any terms contrary to the
          terms agreed with SCR&T. Further, in the event of any conflict or
          inconsistency between the terms you agreed with SCR&T and the terms
          with Lenders, the terms you agreed with SCR&T shall prevail.
        </p>

        <p className="sub-heading">4.2 Insurance Services</p>

        <p>
          <span className="sub-heading">(a) &nbsp;</span>
          Except for certain transit insurance services provided along with the
          Logistics Services, the Insurance Services are provided under group
          insurance schemes provided by registered insurers. SCR&T is not an
          insurer or an insurance intermediary and is merely acting as a group
          manager to administer these schemes. SCR&T will therefore not be
          liable for any insurance payment in case of any issue on quality,
          return, short shipment, breakage or any other issue covered under the
          Insurance Services.
        </p>
        <p>
          <span className="sub-heading">(b) &nbsp;</span>
          Insurance Services are governed by the terms and conditions provided
          by the relevant insurers available on their respective websites should
          you choose to avail of Insurance Services.
        </p>
        <p>
          <span className="sub-heading">(c) &nbsp;</span>
          Transit insurance is available to you only when you avail Logistics
          Services for certain categories of services or services exceeding a
          certain minimum value and will be subject to such terms and conditions
          as applicable at the time of booking. To apply for the Insurance
          Services, you may need to submit certain documents and information
          with us. We may share these documents and information with insurers to
          help the insurer determine whether you are eligible to receive
          Insurance Services and comply with its legal requirements.
        </p>
        <p>
          <span className="sub-heading">(d) &nbsp;</span>
          The insurer will determine your eligibility to avail the relevant
          Insurance Services in accordance with its internal policies. The
          insurer reserves the right to withhold providing Insurance Services to
          you at its discretion. We will charge you such amount as mentioned in
          the Platform from time to time for providing the Insurance Services.
          Further, we will only be liable to make payment towards any of your
          claims, to the extent such claim is processed by the insurer. In the
          event any claim is rejected by the insurer or any claim is only
          partially allowed by the insurer, we will not be liable to pay the
          balance amount remaining unpaid.
        </p>

        <p className="sub-heading">5.	SELLER DISCOUNT COMPENSATION</p>
        <p>
          <span className="sub-heading">5.1 &nbsp;</span>
            The Sellers in their sole discretion, shall determine the price of the goods and the 
            commercial discounts offered to the Buyers. The Buyers shall not have any rights against 
            SCR&T in respect of any schemes, commercial discounts etc. offered by the Sellers. 
        </p>

        <p>
          <span className="sub-heading">5.2 &nbsp;</span>
            SCR&T may communicate to the Buyers, regarding any schemes, 
            commercial discounts etc. being offered by the Sellers. 
        </p>

        <p>
          <span className="sub-heading">5.3 &nbsp;</span>
            SCR&T in its sole discretion, may compensate Sellers for the commercial 
            discounts on sale of goods offered to the Buyers, if any, on the Platform.
            The quantum of amount to be compensated towards such discount shall be the sole 
            discretion of SCR&T and decision of SCR&T shall be final and binding. However, 
            the compensation shall be subject to the Sellers passing the equivalent commercial discount 
            to the Buyers and in any case shall not exceed the commercial discount offered by the Sellers. 
            Any such compensation payable to the sellers shall be paid only when such commercial discounts 
            equivalent to the compensation being paid have been passed on to the Buyer. The compensation 
            amount may be provided to the Sellers in the form and manner by way of commercial debit notes as 
            provided by SCR&T from time to time.  
        </p>

        <p className="sub-heading">6. COMMERCE OFFERINGS</p>
        <p>
          <span className="sub-heading">6.1 &nbsp;</span>
          Users may purchase goods or services from other Users through the
          Website on a business to business basis. The payments for such
          transactions can be made through the Website or subject to such
          conditions as SCR&T may prescribe, directly to the sellers.
        </p>
        <p>
          <span className="sub-heading">6.2 &nbsp;</span>
          Terms of such contracts between the Users, including price, conditions
          of delivery etc, shall be determined between Users entering into the
          transaction. Such terms may be communicated by the selling Users on the Platform. 
          Any discounts, offers etc will be provided by selling
          Users and not by SCR&T. Further, for certain category of products, the
          price may be subject to dynamic fluctuation as determined by the
          sellers and may vary from the time of ordering and delivery of
          products. The Users are advised to exercise their discretion in this
          regard and SCR&T shall not be liable for any price fluctuation or
          variation.
        </p>
        <p>
          <span className="sub-heading">6.3 &nbsp;</span>
          Buyers acknowledge they enter into transactions with other Users on
          the Platform at their own risk and SCR&T does not endorse the sale of
          any goods or services on the Platform. Buyers are advised to verify
          the bona fides of sellers independently.
        </p>
        <p>
          <span className="sub-heading">6.4 &nbsp;</span>
          SCR&T does not provide any warranties with respect to the
          merchantability and fitness of any goods made available by Users for
          sale through the Commerce Offerings. By making any goods or services
          available on the Platform, you undertake that the descriptions,
          images, and other content pertaining to goods or services on their
          platform is accurate and corresponds directly with the appearance,
          nature, quality, purpose and other general features of such goods or
          service.
        </p>
        <p>
          <span className="sub-heading">6.5 &nbsp;</span>
          SCR&T does not make any representations or warranties with respect to
          the title of the sellers or genuineness of the goods. Further, the Users shall ensure that all the all the goods provided to SCR&T for delivery are accompanied by proper documentation (in accordance with applicable laws including but not limited to applicable central, state, integrated or union territory goods and services tax laws (GST) required to ship the goods. Users will be responsible for provide their correct GST registration number. SCR&T shall not hold any responsibility for any incorrect disclosure with respect to GST including any consequent loss of credit and/or any penalty or fine imposed by any government body/authority. In case of any tax, interest, penalty/fine is imposed on SCR&T, on account of incorrect disclosure of information with respect to GST or improper documentation by the Users, then such Users shall indemnify SCR&T with respect to such tax, interest, fine/penalty as may be levied by the government body/authority or any other loss incurred by SCR&T. All applicable statutory tax compliances shall be Users sole responsibility and SCR&T shall not be responsible for any losses, damages resulting from any such non-compliances.
        </p>
        <p>
          <span className="sub-heading">6.6 &nbsp;</span>
          If you are a seller:
          <ul>
            <li>
            You represent and warrant that your goods are compliant with all applicable laws.
            </li>
            <li>
            You agree that you have necessary licences and permits for the sale of goods which shall be including but not limited to the licences required under the applicable laws.
            </li>
            <li>
            You shall be responsible for providing information relating to the goods proposed to be sold by you as per the applicable laws. In this connection, you undertake that all such information shall be accurate in all respects. You shall not exaggerate or over emphasise the attributes of such items or services so as to mislead buyers on the Platform in any manner.
            </li>
            <li>
            In the event you are selling or dealing with any food article on the Platform or you are a ‘food business operator’ as defined under Food Safety and Standards Act, 2006 and the applicable rules and regulations thereunder, you shall ensure compliance with the said Act, rules and regulations and you shall be solely liable for any breach or non-compliance thereof.
            </li>
            <li>
            You shall ensure that every package of food distributed or sold by you shall carry all the information on the label, as per applicable laws, products categories and specifically the Food Safety and Standards (Packaging) Regulations, 2018 & Food Safety and Standards (Labelling and Display) Regulations, 2020.
            </li>
            <li>
            You shall ensure that any food article delivered to buyers have a shelf life of 30 percent or 45 days before expiry at the time of delivery to the buyers. If you breach this clause or if receive information that you have violated this condition, without prejudice to any liability you may be subjected to under applicable laws, we will remove the listing of your goods from the Platform and suspend your access to the SCR&T Services.
            </li>
            <li>
            You shall ensure that any additional place of business added by you on the Platform is a valid address as per applicable law and there are no duplicate or wrong additional place of business added by you.
            </li>
            <li>
            You undertake that you will not list or sell any counterfeit or duplicate products to the buyers on the Platform.
            </li>
            <li>
            You will ensure that appropriate checks and measures are in place to ensure the quality and authenticity of the products being sold to the buyers on the Platform.
            </li>
            <li>
            You shall ensure that the products listed on the Platform comply with Legal Metrology (Packaged Commodities) Rules 2011 including Rule 6 (10) of Legal Metrology (Packaged Commodities) Rules 2011 and any amendment made to the same from time to time;
            </li>
            <li>
            You shall be responsible for displaying all the good’s label declarations on the Platform as required under Rule 6 (1) of Legal Metrology (Packaged Commodities) Rules 2011for the buyer’s review;
            </li>
            <li>
            You shall ensure compliance under the Bureau of India Standards Act, 1986 ("BIS Act”) including all applicable Quality Control Orders issued under the BIS Act, ISI Mark Scheme & rules and regulations made thereunder and all applicable legislations, in each case, as amended from time to time. You shall obtain and shall continue to maintain in force, all certificates and authorisations needed under applicable laws for the lawful sale of the goods.
            </li>
            <li>
            You shall ensure compliance with the Infant Milk Substitutes Feeding Bottles and Infant Foods (Regulation of Production, Supply and Distribution) Act, 1992 as amended from time to time through amendments, notifications, clarifications, etc.
            </li>
            <li>
            Any non-compliance by you with respect to your goods, which get listed on the Platform, you shall be solely responsible and liable for the legal repercussions;
            </li>
            <li>
            You must ensure that the goods you are offering for sale do not infringe upon the intellectual property (such as patent, trademark, copyright, proprietary rights, design etc), trade secret or other proprietary rights or rights of publicity or privacy rights of third parties.
            </li>
            <li>
            You shall, whenever requested by SCR&T, immediately provide all licences, registrations, permissions, authorisations, certifications applicable on the goods being sold by you.
            </li>
            
          </ul>
        </p>
        <p>
          <span className="sub-heading">6.7 &nbsp;</span>
          Further, you will also be liable to indemnify the buyer and/or SCR&T from and against all claims, demands, actions, suits, damages, liabilities, losses, settlements, judgments, fines, penalties, costs and expenses (including but not limited to reasonable attorney’s fees and costs), involving any claim including third-party claims, which arise out of or relate to any breach of your obligations set out in Clause 5.6 above, commission of unlawful act or non-commission of lawful act by you, negligence act by you in regards to your goods listed on Platform. Additionally, SCR&T shall have the discretion to set-off such claims, damages, liabilities, losses, settlements, costs, fines, penalties and expenses incurred by SCR&T and/or the buyer from any amount due to paid to you.
        </p>
        <p>
          <span className="sub-heading">6.8 &nbsp;</span>
          In case any counterfeit or duplicate products are sold to a buyer(s), without prejudice to any liability you may be subjected to under applicable laws, we will remove the listing of your goods from the Platform and suspend/disable your access to SCR&T Services. Further, addition to the obligations set out in this Clause 5.7 above, you shall also be liable to refund the amounts paid by buyer(s) who have purchased such counterfeit or duplicate products from you on the Platform. 
        </p>
        <p>
          <span className="sub-heading">6.9 &nbsp;</span>
          As a seller, you may also be required to sign a separate undertaking in the form and manner as provided by SCR&T, and such undertaking will capture all obligations while using the Platform and accessing SCR&T’s Services in accordance with the terms hereof.
        </p>
        <p>
          <span className="sub-heading">6.10 &nbsp;</span>
          If you are a buyer, you hereby agree and acknowledge that SCR&T has no control over the actions of the sellers or other Users on the Platform, including the listings of goods or services pursuant to Commerce Offerings on the Platform and no claims shall lie against SCR&T and/or any of its officers and representatives for any of the actions of the sellers or any other Users on the Platform. You specifically waive any claims that you may have in this behalf under any applicable law. 
        </p>
        <p>
          <span className="sub-heading">6.11 &nbsp;</span>
          SCR&T is not responsible for any non-performance or breach of any
          contract entered into between the Users. SCR&T cannot and does not
          guarantee that the concerned Users shall perform any transaction
          pursuant to the usage of Website. SCR&T shall not and is not required
          to mediate or resolve any dispute or disagreement between the Users in
          this regard.
        </p>
        <p>
          <span className="sub-heading">6.12 &nbsp;</span>
          The Platform allows Users to connect and communicate with each other
          on the Platform and exchange ideas. SCR&T does not hold any liability
          on the content (including text, photo and video) shared by the Users
          with each other.
        </p>
        <p>
          <span className="sub-heading">6.13 &nbsp;</span>
          Any data shared by a User can be used by SCR&T for training, quality
          monitoring, legal and compliance purposes.
        </p>
        <p>
          <span className="sub-heading">6.14 &nbsp;</span>
          In the event you are a seller of goods on the Platform, SCR&T may
          provide you with an option to enter the invoice number for the
          relevant transaction. In such an event, you shall ensure that the
          invoice number is unique and you have not previously used or will
          thereafter use the said invoice number for any other order or
          transaction. You will further ensure that you follow the invoice
          series mentioned therein solely for transactions undertaken on the
          Platform
        </p>
        <p>
          <span className="sub-heading">6.15 &nbsp;</span>
          In the event any product delivered to the buyer suffers from a quality
          issue and the buyer wishes to return such product, the terms and
          conditions of such return shall be as set out in our Return Shipments
          Policy annexed as <b>Annexure C</b> hereto.
        </p>
        <p className="sub-heading">
          6.16 &nbsp; Payments towards transactions facilitated by the Platform
        </p>
        <p>
          The buyers shall make the payment of amounts to the sellers towards
          the transactions, through SCR&T and the Platform. In the event the
          buyer fails to make the payment to SCR&T for any reason whatsoever,
          including pursuant to any alternate credit arrangement agreed with the
          seller, irrespective of whether the goods are delivered or not, SCR&T
          shall not be liable to make any payment to the seller. In the event
          the buyer makes only part-payment, SCR&T shall pass on such
          part-payment amount to the seller, after deducting its facilitation
          fee. SCR&T may also allow for payments to be directly disbursed by the
          buying Users to the selling Users, subject to the conditions as set
          out hereinafter and such other conditions and eligibility criteria of
          Users and transactions as may be stipulated from time to time.
          The buyers shall comply with all the applicable laws/regulations in relation to the cash transaction as stipulated under the applicable tax laws and/or any other applicable laws including the restriction on cash transaction of INR 2,00,000/- or more under the Income Tax Act, 1961. In the event of any breach or violation of the applicable tax laws and/or any other applicable laws by the buyers in relation to the cash transaction, the buyers indemnify Solv for any loss, damage, claim, action, costs, charges, fines, penalties and expenses (including reasonable attorney fees) which Solv may suffer or incur as a result of buyers’ actions or breach or violation.

        </p>
        <p className="sub-heading">(a) Payments through Platform</p>
        <p >
          (i) Upon confirmation of an order by a seller, the buyer shall make
          payment of the amounts towards the transactions to SCR&T. You further
          acknowledge that SCR&T, shall after obtaining confirmation from the
          buyer of the Platform of the delivery of the goods in good condition,
          shall transfer the amounts paid by the buyer towards the goods to the
          seller no later than 3 days from the receipt of the buyer’s
          confirmation and subject to completion of the transaction in
          accordance with laws and the contract between the buyer and youseller.
          SCR&T shall not be liable to pay any amount to the seller if it has
          not received the relevant payment from the buyer. In the event the
          buyer of any goods raises a dispute, the relevant payment shall be put
          on hold and SCR&T shall not remit the sale proceeds to the seller
          until the resolution of such dispute. Both the seller and buyer shall
          cooperate for an expeditious resolution of the dispute and provide
          appropriate documentary proof and information as required by SCR&T in
          this regard.
        </p>
        <p>
          (ii) In the event SCR&T is facilitating any buyer to make payments to
          a seller through itself or any payment gateway on the Platform and
          such payment is not completed for any reason whatsoever, SCR&T and its
          payment gateway partners will not be liable in any manner and the
          buyer shall directly liaise with the relevant payment bank. In such an
          event, SCR&T may provide reasonable cooperation to the buyer to
          process a refund. Further, in the event of any chargeback or return
          pursuant to any payment failure, the relevant seller and buyer shall
          be liable to immediately provide all information and documents as
          required by SCR&T to aid SCR&T in connection with any disputes. In the
          event the seller or buyer, as the case may be, fails to provide the
          required information, SCR&T shall be entitled to recover the relevant
          amounts from the party in any future transaction undertaken on the
          Platform.
        </p>
        <p>
          (iii)	
It is further clarified that as per the provisions of this Clause 5.16 (a), in the event of any excess payment of sale proceeds or incorrect payment done inadvertently by SCR&T to a seller on the Platform, any such excess amount shall either be adjusted in any future payments that are to be made by SCR&T to such seller or shall be refunded to SCR&T by the relevant seller whenever directed by SCR&T.
        </p>
        <p className="sub-heading">
          (b) Payments directly made to selling Users
        </p>
        <p>
          (i) Pursuant to stipulation of eligibility criteria by SCR&T from time
          to time, in the event the buyers qualify to make payment of sale
          consideration towards transactions on the Platform directly to the
          relevant sellers, the terms of such payment including mode of payment,
          any credit arrangement, etc, shall be directly determined and agreed
          between the relevant Users, without any involvement of SCR&T. In such
          cases, SCR&T shall not have any obligation to receive payment from
          buyer or pass on such payment to seller. Any discounts, offers etc
          will be provided by selling Users and not by SCR&T and SCR&T shall
          have no knowledge of such discounts, offers, etc.
        </p>
        <p>
          (ii) You further acknowledge that where the buying User directly
          disburses payment of amounts towards transactions to the selling
          Users, SCR&T does not provide settlement as a service, i.e.,
          consideration with respect to such transactions will not be collected
          by SCR&T and consequently no taxes will be collected at source in
          terms of Section 52 of the Central Goods and Services Tax Act, 2017.
          SCR&T acts as a mere facilitator in this regard and has no liability
          in terms of any breach of condition by either party. The relevant
          seller and buyer of the transaction need to assess their respective
          tax liability and pay the applicable taxes, as required under laws and
          ensure all compliances in this regard.
        </p>
        <p>
          (iii) Where the buying User opts to make payments directly to the
          selling Users and such payment is not completed for any reason
          whatsoever, SCR&T will not be liable in any manner and the buyer and
          seller shall directly liaise with the relevant payment bank. SCR&T
          will not be able to provide any additional support in this regard. We
          however encourage the buyers to provide all information and documents
          as required by SCR&T in connection with any dispute. If there is
          reasonable cause basis the information provided that the selling User
          has failed to deliver the services and or goods or refuses to refund
          the impugned amounts, we may at our discretion, remove the listing of
          the selling User services from the Platform and suspend its access to
          the SCR&T Services. SCR&T shall also be entitled to recover the
          relevant amounts from the party in any future transaction undertaken
          on the Platform.
        </p>
        <p>
          (iv) The seller’s obligation to pay the facilitation fee to SCR&T
          shall continue to subsist, regardless of whether the payment towards
          any transaction is routed through the Platform or not. SCR&T shall
          prescribe the mode of payment of such facilitation fee to the seller
          and the seller shall make the payment in the manner as required by
          SCR&T.
        </p>
        <p>
        The sellers on monthly basis or whenever requested by SCR&T shall issue no due certificate in the format prescribed by SCR&T. Upon receipt of request from SCR&T, if the sellers fail to issue the no due certificate or fails to share details of orders that are not settled, within 15 days of sending such request (to share the no due certificate), it shall be deemed that sellers have issued the no-due certificate and all the payments as well as deliveries have been settled by SCR&T. Further, as also stated above, in no event SCR&T shall be liable to make any payment to the sellers, if the buyer fails to make the payment to SCR&T for any reason whatsoever, irrespective of whether the goods are delivered or not.
        </p>
        <p className="sub-heading">7. PERMITTED USE OF WEBSITE</p>
        <p>
          <span className="sub-heading">7.1 &nbsp;</span>
          You may use the Website and the SCR&T Services solely for your own
          use, subject to the terms of this Agreement that you may accept and
          agree to be bound by. We grant you a non-sub-licensable, revocable,
          non-transferable, non-exclusive, limited period license to use
          Website, but solely as necessary to use the SCR&T Services and in
          accordance with the Agreement. All of our rights not expressly granted
          by this license are hereby retained.
        </p>
        <p>
          <span className="sub-heading">7.2 &nbsp;</span>
          All content, data, design, information or other materials available on
          and underlying the Website (collectively <strong>“Content”</strong>),
          including but not limited to software underlying the Website or which
          are required for accessing or using the Website, images, text,
          layouts, arrangements, displays, illustrations, photographs, graphics,
          audio and video clips, HTML files and other content, logos, trademarks
          including the trademark{" "}
          <img className="tnc-solv-logo" src={logo} alt="logo" /> and other
          trademarks that may be used on the Website are the property of SCR&T
          and/or its licensors and are protected by copyright and/or other
          applicable intellectual property right laws. You are not permitted to
          copy, reproduce, scrap, publish, perform, communicate to the public in
          any manner whatsoever, transmit, sell, license, distribute or transfer
          in any manner whatsoever, adapt, modify, translate, disassemble,
          decompile or reverse engineer, create derivative works from, or in any
          way use or exploit the Website, the SCR&T Services, the Content or any
          part thereof. You shall not remove or obliterate any copyright,
          trademark or proprietary rights notices from the Content or the
          Website and shall reproduce all such notices on all authorized copies
          of the Content. Any use of the Website, the Service and/or Content in
          violation of these Terms may violate copyright, trademark and other
          applicable laws, and could result in appropriate legal action.
          Notwithstanding anything contained in this Agreement, SCR&T may at its
          discretion, provide the Users with an option to replicate and use the
          Content, including images, as available on the Platform, which may
          have been provided by SCR&T or other Users, for cataloguing the
          products on the Platform or any other permitted purposes. In the event
          SCR&T provides such feature, you hereby acknowledge and agree that the
          other Users may use the Content provided by you; and upon such usage,
          SCR&T and the relevant User(s) shall not be liable in any manner under
          applicable laws or otherwise and you shall not have any claim or
          objection whatsoever against such usage.
        </p>
        <p>
          <span className="sub-heading">7.3 &nbsp;</span>
          SCR&T’s role in providing the Platform to you is limited to that of a
          facilitator. SCR&T does not initiate any transactions entered between
          Users or between Users and Third Party Service Providers on its
          Platform. SCR&T does not select or modify any information provided by
          Users, including but not limited to information with respect to
          transaction, conversations and listings. SCR&T will not be liable for
          the content generated or published in listings, chats or conversations
          between Users or with Partners on its Platform.
        </p>
        <p>
          <span className="sub-heading">7.4 &nbsp;</span>
          You shall not use the Website, the SCR&T Services, the Content or any
          part thereof for any purpose that is unlawful or not permitted by
          these Terms. You will not display or upload any photo, picture,
          illustration or graphic representation which may violate the
          proprietary rights or copyright of any other person and you shall
          ensure that you are authorized to upload or display such materials.
          Without prejudice to the generality of the above, you shall not use
          the Website, the SCR&T Services, the Content or any part thereof to
          host, display, upload, modify, publish, transmit, update or share any
          information that:
        </p>
        <p>
          <span className="sub-heading">(a) &nbsp;</span>
          belongs to another person and to which the you do not have any right
          to;
        </p>
        <p>
          <span className="sub-heading">(b) &nbsp;</span>
          impersonate another person;
        </p>
        <p>
          <span className="sub-heading">(c) &nbsp;</span>
          is grossly harmful, harassing, blasphemous, defamatory, obscene,
          pornographic, paedophilic, libelous, invasive of another's privacy,
          hateful, or racially, ethnically objectionable, disparaging, relating
          or encouraging money laundering or gambling, or otherwise unlawful in
          any manner whatever;
        </p>
        <p>
          <span className="sub-heading">(d) &nbsp;</span>
          harm minors in any way;
        </p>
        <p>
          <span className="sub-heading">(e) &nbsp;</span>
          infringes any patent, trademark, copyright or other proprietary
          rights;
        </p>
        <p>
          <span className="sub-heading">(f) &nbsp;</span>
          violates any law for the time being in force or deceives or misleads
          SCR&T about the origin of such messages or communicates any
          information which is grossly offensive or menacing in nature;
        </p>
        <p>
          <span className="sub-heading">(g) &nbsp;</span>
          contains software viruses or any other computer code, files or
          programs designed to interrupt, destroy or limit the functionality of
          any computer resource;
        </p>
        <p>
          <span className="sub-heading">(h) &nbsp;</span>
          threatens the unity, integrity, defense, security or sovereignty of
          India, friendly relations with foreign states, or public order or
          causes incitement to the commission of any cognizable offence or
          prevents investigation of any offence or is insulting any other
          nation;
        </p>
        <p>
          <span className="sub-heading">(i) &nbsp;</span>
          is unsolicited or unauthorized advertising, junk mail, or spam; or
        </p>
        <p>
          <span className="sub-heading">(j) &nbsp;</span>
          sells any counterfeit goods or illegal goods or restricted goods on
          the Platform.
        </p>
        <p>
          Further, you shall ensure that you comply with the terms of SCR&T’s
          ‘Policy on Sale of Illegal and Restricted Products and Services on
          Solv Platform’, set out as <strong>Annexure D </strong> hereto, at all
          times during use of Platform. If you are, or if we have reasonable
          cause to suspect that you are, in violation of any of the Terms, SCR&T
          reserves the right to immediately restrict, suspend, or terminate your
          access to the Website and/or use of the SCR&T Services. SCR&T will not
          be responsible for any claims arising out of any violation of these
          Terms that have taken place without its knowledge.
        </p>
        <p>
          <span className="sub-heading">7.5 &nbsp;</span>
          In the event of any downtime or data breach, hacking or such other
          incident on the Platform or Website, which may or may not adversely
          affect you, your sole remedy shall be as set out under paragraph 15.1
          below and you shall not directly or indirectly post any offensive or
          derogatory content against SCR&T or any of its affiliates, in any
          media including social media, in any form whatsoever.
        </p>

        <p>
          <span className="sub-heading">7.6 &nbsp;</span>
          Further, by availing the SCR&T Services, you hereby expressly
          acknowledge that SCR&T shall at its discretion, be entitled to use
          your name, tradename, brand name, logos etc in any marketing
          materials, promotional materials or advertisements of SCR&T or its
          Platform and SCR&T shall be deemed to have acquired a license for such
          usage and you shall not raise any objection in this regard.
        </p>

        <p className="sub-heading">8. CHARGES</p>
        <p>
          <span className="sub-heading">8.1</span>
          &nbsp; SCR&T shall be entitled to charge fees such as platform fees, convenience fees, onboarding fees etc. for the SCR&T Services
          as per the respective SCR&T Service that you subscribe/ avail on the
          Website. SCR&T will deduct the applicable fees when you make any
          payment through the Website or in such other manner as communicated to
          you from time to time. The fees as applicable from time to time shall
          be detailed on our Website or as communicated to you from time to time
          including by way of email.
        </p>
        <p>
          <span className="sub-heading">8.2</span>
          &nbsp; The fees paid to SCR&T are non-refundable. SCR&T’s records
          shall be final and binding as to any such commencement of the SCR&T
          Service. SCR&T may also receive a referral / facilitation fee from the
          Third Party Service Provider and/or other third party banks / other
          institutions for (a) assisting with the settlement of outstanding
          amounts or dues owed to them by you and / or (b) for referring their
          services to you. By accepting these Terms, you confirm that you have
          no objection to the same.
        </p>
        <p>
          <span className="sub-heading">8.3</span>
          &nbsp; In the event any Third Party Service Provider or a User charges
          a fee for a transaction, notwithstanding the unsuccessful consummation
          of such transaction, SCR&T shall not be liable to Users for
          reimbursement or refund of such fee.
        </p>

        <p className="sub-heading">9. LOYALTY AND REFERRAL PROGRAMMES</p>
        <p>
          <span className="sub-heading">9.1</span>
          &nbsp; Loyalty Programme
        </p>
        <p>
          <span className="sub-heading">(a)</span>
          &nbsp; We may offer Loyalty Programme for Users on the Platform from
          time to time. The Loyalty Programme seeks to reward Users who avail
          the benefits of SCR&T Services. Eligible Users may redeem their
          loyalty points against Third Party Offerings or transactions with
          other Users on the Platform. Users can participate in the Loyalty
          Programme once they have created their Accounts.
        </p>
        <p>
          <span className="sub-heading">(b)</span>
          &nbsp; We may grant you loyalty points when you refer other Users to
          the Website. You may also earn loyalty points when you avail Third
          Party Offerings or undertake commercial transactions with other Users
          through the Website. You will be able to view the total number of
          loyalty points you have accrued when you view your Account
          information.
        </p>
        <p>
          <span className="sub-heading">(c)</span>
          &nbsp; The loyalty points will be valid only on the Website. They
          cannot be redeemed offline against other Users, Third Party Service
          Providers, or us.
        </p>
        <p>
          <span className="sub-heading">(d)</span>
          &nbsp; The loyalty points will be awarded solely at our discretion and
          the options available to redeem them are also at our discretion. We
          will determine the rules for allocating loyalty points. Except as
          otherwise expressly prohibited or limited by applicable laws, SCR&T
          has the right to change, limit, modify or cancel the Loyalty Programme
          at any time, with or without notice.
        </p>
        <p>
          <span className="sub-heading">(e)</span>
          &nbsp; The loyalty points are the property of SCR&T. You cannot
          transfer or sell or barter the loyalty points to any other person,
          including Users of the Platform.
        </p>
        <p>
          <span className="sub-heading">(f)</span>
          &nbsp; We may need to share certain information about you with our
          Partners in order to offer the Loyalty Programme to you. Please refer
          to the Privacy Policy to understand the nature of information which
          may be shared.
        </p>

        <p>
          <span className="sub-heading">9.2</span>
          &nbsp;Referral Programme
        </p>
        <p>
          <span className="sub-heading">(a)</span>
          &nbsp; We may also offer Referral Programme on the Platform from time
          to time for rewarding Users who successfully refer other business
          entities to onboard as Users. The details of the current Referral
          Programme offered by us are set out here.
        </p>

        <p className="sub-heading">10. PRIVACY</p>
        <p>
          The Privacy Policy at{" "}
          <a
            className="anchor-link"
            href="https://www.solvezy.com/privacy-policy/"
          >
            {" "}
            https://www.solvezy.com/privacy-policy/{" "}
          </a>{" "}
          relating to our collection, processing, use, storage and disclosure of
          User Data is incorporated by this reference into, and made a part of,
          these Terms. By agreeing to the Terms, you agree to the use of the
          User Data and confidential information as per the Privacy Policy.
        </p>

        <p className="sub-heading">11. TERMINATION</p>
        <p>
          <span className="sub-heading">11.1</span>
          &nbsp; This Agreement is binding from the time you access this Website
          and will continue until either you or SCR&T terminates this Agreement.
        </p>
        <p>
          <span className="sub-heading">11.2</span>
          &nbsp; SCR&T may terminate the Agreement and de-activate your Account
          forthwith by notice to you at your email address registered with us in
          the event that you are in breach of this Agreement.
        </p>
        <p>
          <span className="sub-heading">11.3</span>
          &nbsp; SCR&T may also terminate this Agreement and de-activate your
          Account for any reason by giving you notice of 10 (ten) days or such
          other appropriate time period, addressed to your email address
          registered with us.
        </p>
        <p>
          <span className="sub-heading">11.4</span>
          &nbsp; You may terminate this Agreement and request deactivation of
          your Account by written notice by email addressed to SCR&T at{" "}
          <b>cs@solvezy.com</b>. Your Account will be deactivated within 30
          working days from receipt of such email.
        </p>
        <p>
          <span className="sub-heading">11.5</span>
          &nbsp; Upon termination, your right and license to access and/or use
          the Website or avail any the SCR&T Services immediately ceases; your
          Account will be deactivated. Further, your personal information that
          is in the possession of SCR&T will be dealt with in accordance with
          our Privacy Policy.
        </p>

        <p className="sub-heading">12. REPRESENTATIONS AND WARRANTIES</p>
        <p>
          <span className="sub-heading">12.1</span>
          &nbsp; The User represents and warrants that:
        </p>
        <p>
          <span className="sub-heading">(a)</span>
          &nbsp; (i) if it is an entity, it is a company, duly incorporated
          under the laws of India, or a limited liability partnership registered
          under the Limited Liability Partnership Act, 2008, or a sole
          proprietorship/partnership firm, as the case may be, validly existing
          and in good standing, and has all requisite power and authority to own
          and operate its business and properties and to carry on its business
          as such business is now being conducted; (ii) if an individual he/she
          is not insolvent and has all necessary permits to carry out his/her
          business;
        </p>
        <p>
          <span className="sub-heading">(b)</span>
          &nbsp; he/she/it has full legal capacity and has obtained all
          necessary statutory and regulatory permissions, approvals and permits
          for the running and operation of its establishment, for the conduct of
          its business and to execute, deliver and perform this Agreement and
          has been or will be duly authorized by all necessary corporate actions
          and if it is an entity, it has authorised the Authorised
          Representative with necessary authorisations to perform actions on its
          behalf for the purposes of the SCR&T Services and the Third Party
          Service Offerings;
        </p>
        <p>
          <span className="sub-heading">(c)</span>
          &nbsp; this Agreement has been duly executed and forms legal, valid
          and binding obligations upon the User, enforceable in accordance with
          its terms;
        </p>
        <p>
          <span className="sub-heading">(d)</span>
          &nbsp; no claim, litigation, arbitration, claim, suit, action,
          investigation or proceeding or order, judgment, injunction, decree,
          award, settlement or stipulation of or before any arbitrator, tribunal
          or government authority, is pending or outstanding against it/her/him
          affecting its ability to enter into or perform this Agreement.
        </p>
        <p>
          In case these Terms are being entered into by the Authorized
          Representative, then said individual represents and warrants that it
          has validly entered into the Agreement and has the legal power to do
          so, on behalf of the User.
        </p>
        <p>
          <span className="sub-heading">12.2</span>
          &nbsp; The Users shall not avail any SCR&T Services if they are the
          subject of any sanctions imposed by the regulatory authorities. The
          Users must comply with all other export and re-export restrictions
          that may apply to goods, software, technology and services and ensure
          that its clients and vendors are not subject of any sanctions. The
          Users will also adopt appropriate processes to prevent offering any
          illegal gratification in the form of bribes or kickbacks either in
          cash or in kind in the course of all dealings with each other and with
          SCR&T. Each User represents, warrants and undertakes that it has not
          and shall not offer, promise, give, encourage, solicit, receive or
          otherwise engage in acts of bribery or corruption in relation to the
          SCR&T Services (including without limitation any facilitation
          payment), or to obtain or retain business or any advantage in business
          for any member of the other party, and has and shall ensure to the
          fullest extent possible that its employees and agents and others under
          its direction or control do not do so. The Users shall also ensure
          that they comply with all applicable laws while availing the SCR&T
          Services, including labour laws and they will not engage in or
          encourage slavery and child labour in any manner whatsoever. Any
          instances of such violations will be viewed in a serious manner and We
          reserve the right to take all appropriate actions or remedies as may
          be required under the circumstances.
        </p>

        <p className="sub-heading">13. DISCLAIMER OF WARRANTIES</p>
        <p>
          WE PROVIDE SCR&T SERVICES, OUR CONTENT, AND ALL CONTENT AND MATERIALS
          AVAILABLE THROUGH THE SCR&T SERVICES AND WEBSITE “AS IS” AND “AS
          AVAILABLE” WITHOUT ANY WARRANTY OR CONDITION OF ANY KIND, EXPRESS OR
          IMPLIED. SCR&T, ON BEHALF OF ITSELF, ITS AFFILIATES AND ITS LICENSORS,
          DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
          RELATING TO THE SCR&T SERVICES, OUR CONTENT, OR THE WEBSITE, INCLUDING
          IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, QUIET ENJOYMENT, QUALITY, ACCURACY, TITLE AND
          NON-INFRINGEMENT AND ANY WARRANTY ARISING OUT OF COURSE OF DEALING,
          USAGE OR TRADE. FURTHER THE CURRENT VERSION OF PLATFORM MAY BE
          SUSCEPTIBLE TO BUGS AND GLITCHES.
        </p>
        <p>
          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
          FROM SCR&T SERVICES, FROM OUR CONTENT, OR WHICH IS OTHERWISE AVAILABLE
          THROUGH SCR&T SERVICES WILL CREATE ANY WARRANTY REGARDING SCR&T, THIRD
          PARTY SERVICE OFFERINGS, PARTNER SERVICES OR ANY SERVICE THAT IS NOT
          EXPRESSLY STATED IN THESE TERMS. YOU WILL USE THE SCR&T SERVICES AND
          USE, ACCESS, DOWNLOAD, OR OTHERWISE OBTAIN OUR CONTENT, OR OTHER
          CONTENT AVAILABLE THROUGH SCR&T SERVICES, AT YOUR OWN DISCRETION AND
          RISK. YOU ASSUME ALL RISK FOR ANY DAMAGE THAT MAY RESULT FROM YOUR USE
          OF OR ACCESS TO THE SCR&T SERVICES, FROM YOUR DEALINGS WITH ANY THIRD
          PARTY SERVICE PROVIDERS, OTHER MEMBERS OR PARTNERS AND FROM YOUR USE
          OF OUR CONTENT OR OTHER CONTENT AVAILABLE THROUGH SCR&T SERVICES. YOU
          ACKNOWLEDGE THAT SCR&T IS SOLELY A FACILITATOR BETWEEN YOU AND THIRD
          PARTY SERVICE PROVIDERS. SCR&T EXPRESSLY DISCLAIMS ANY LIABILITY FOR
          ANY ERRORS OR OMISSIONS IN OUR CONTENT OR SCR&T SERVICES, INCLUDING
          THE INACCURACY OR INCOMPLETENESS OF ANY SUCH USER VERIFICATION, CREDIT
          REPORTS, CREDIT SCORES, OFFERS OR OTHER INFORMATION, TOOLS OR ANALYSES
          AVAILABLE THROUGH SCR&T SERVICES OR OUR CONTENT ON THE WEBSITE.
        </p>

        <p className="sub-heading">14. LIMITATION OF LIABILITY</p>
        <p>
          SCR&T AND SCR&T PARTIES WILL NOT BE LIABLE TO YOU OR ANY THIRD PARTY
          FOR ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE
          DAMAGES (SUCH AS LOSS OF PROFITS, LOSS OF GOODWILL, LOSS OF USE, LOSS
          OF DATA, BUSINESS INTERRUPTION, OR OTHER INTANGIBLE LOSSES) ARISING
          OUT OF OR RELATING IN ANY WAY TO SCR&T SERVICES, OUR CONTENT OR USE OF
          THE WEBSITE.
        </p>
        <p>
          IN NO EVENT WILL THE TOTAL LIABILITY OF THE SCR&T PARTIES TO YOU FOR
          ALL CLAIMS ARISING OUT OF OR RELATING TO THE USE OF, OR ANY INABILITY
          TO USE ANY PORTION OF, SCR&T SERVICES, OR OTHERWISE ARISING OUT OF OR
          RELATING TO THE AGREEMENT, WHETHER IN CONTRACT, TORT, OR OTHERWISE,
          EXCEED IN THE AGGREGATE INR 5000 (INDIAN RUPEES FIVE THOUSAND ONLY).
        </p>

        <p className="sub-heading">15. INDEMNITY</p>
        <p>
          You will defend (if requested by any SCR&T Party), indemnify, and hold
          harmless the SCR&T Parties from and against any and all claims,
          losses, expenses, demands, or liabilities, including attorneys' fees
          and costs, incurred by the SCR&T Parties in connection with any claim
          by a third party arising out of or in any way related to: (1) your use
          of SCR&T Services, our Content or the Website; (2) your violation or
          alleged violation of the Agreement or your violation or alleged
          violation of any applicable law; (3) your infringement or alleged
          infringement of any intellectual property or other right of any other
          person or entity; or (4) any dispute between you and a third party,
          including any provider of third party content or other Users. You must
          not settle any such claim or matter without the prior written consent
          of SCR&T. The SCR&T reserve the right, at their own expense, to assume
          the exclusive defence and control of any matter subject to
          indemnification by you, and you further agree that you will cooperate
          fully in the defence of any such claims.
        </p>

        <p className="sub-heading">16. GENERAL PROVISIONS</p>
        <p>
          <span className="sub-heading">
            16.1 Grievances, Customer Care and Nodal Officer
          </span>
        </p>
        <p>
        If you have any other concern or grievance with respect to any of content/information/data/products or services offered on the Website, please send an e-mail to our grievance officer/nodal officer, whose details are mentioned herein below:
        </p>
        <p>Name: Ms. Khushboo Agarwal</p>
        <p>Email ID: <a className='mail-anchor' href="mailto:khusboo.agarwal@solvezy.com">khusboo.agarwal@solvezy.com</a></p>
        <p>
        Address: 2nd Floor, Indiqube Edge, Khata No.571/630/6/4, Ambalipura Village, Varthur Hobli, Mahadevpura Div, Bangalore 560102
        </p>
        <p>
        Phone No.: 080 68242424
        </p>
        <p>
        Upon receipt of your complaint by the grievance officer/nodal officer, an immediate compliant acknowledgement ID shall be assigned to your complaint, which shall also be intimated to you to track the status of the complaint. The nodal officer shall redress the compliant within 15 working days from the date of receipt of compliant. However, the following delays are excluded for calculation of time taken for redressal of your complaint:
        </p>
        <p>
        (a)Time taken by you to provide relevant information and documents, if any; and
        </p>
        <p>
       (b)In case the redressal involves a third party, any delay caused by such third party.
        </p>
        <p>
          <span className="sub-heading">16.2 Assignment</span>
        </p>
        <p>
          You cannot assign or otherwise transfer the Terms, SCR&T Services or
          any rights hereunder to any third party. Our rights under the Terms
          with respect to SCR&T Services are freely transferable to any third
          parties without the requirement of seeking your consent.{" "}
        </p>
        <p>
          <span className="sub-heading">16.3 Governing Law</span>
        </p>
        <p>
          This Agreement shall be construed, interpreted and applied in
          accordance with, and shall be governed by, the laws applicable in
          India. The courts in Bengaluru shall have exclusive jurisdiction to
          entertain any dispute or suit arising out of or in relation to this
          Agreement.{" "}
        </p>

        <div className="text-center">
          <p className="sub-heading">ANNEXURE-1</p>
          <p className="sub-heading">Format for Individual User Consent</p>
          <p className="sub-heading">
            CONSENT IN RELATION TO ACCESS TO CREDIT INFORMATION THROUGH EXPERIAN
          </p>
        </div>
        <div className="annexture-content">
          <p>
            This End User Agreement (the{" "}
            <span className="sub-heading">“Agreement”</span>) is made between
            you (the <span className="sub-heading">“User”</span> or{" "}
            <span className="sub-heading">“You”</span>), on behalf of
            _____________ and Standard Chartered Research and Technology India
            Private Limited, a private limited company having its registered
            office at 2nd Floor, Indiqube Edge, Khata No.571/630/6/4, Ambalipura
            Village, Varthur Hobli, Mahadevpura Div Bengaluru, Karnataka -
            560102, India (<span className="sub-heading">“SCR&T”</span>,{" "}
            <span className="sub-heading">“Us”</span> or{" "}
            <span className="sub-heading">“We”</span>, which term shall include
            its successors and permitted assigns). The User and SCR&T shall be
            collectively referred to as the{" "}
            <span className="sub-heading">“Parties”</span> and individually as a{" "}
            <span className="sub-heading">“Party”</span>.
          </p>
          <p>
            You hereby consent to SCR&T being appointed as your authorised
            representative to receive your Credit Information from Experian for
            the End Use Purpose (as defined below).
          </p>
          <p className="sub-heading">
            BY EXECUTING THIS AGREEMENT / CONSENT FORM, YOU ARE EXPRESSLY
            AGREEING TO ACCESS THE EXPERIAN CREDIT INFORMATION REPORT AND CREDIT
            SCORE, AGGREGATE SCORES, INFERENCES, REFERENCES AND DETAILS (AS
            DEFINED BELOW) (TOGETHER REFERRED AS “CREDIT INFORMATION”). YOU
            HEREBY ALSO CONSENT TO SUCH CREDIT INFORMATION BEING PROVIDED BY
            EXPERIAN TO YOU AND SCR&T BY USING EXPERIAN TOOLS, ALGORITHMS AND
            DEVICES AND YOU HEREBY AGREE, ACKNOWLEDGE AND ACCEPT THE TERMS AND
            CONDITIONS SET FORTH HEREIN.
          </p>
          <p className="sub-heading">Terms and Conditions:</p>
          <p className="sub-heading">
            Information Collection, Use, Confidentiality, No-Disclosure and Data
            Purging
          </p>
          <p>
            SCR&T shall access your Credit Information as your authorized
            representative and SCR&T shall use the Credit Information for
            limited End Use Purpose consisting of and in relation to the
            services proposed to be availed by you from SCR&T. Detailed terms
            with respect to the storage and use of Credit Information shall be
            as per the privacy policy available here.
          </p>
          <p>
            The Parties agree to protect and keep confidential the Credit
            Information both online and offline.
          </p>
          <p>
            The Credit Information shared by you, or received by us on your
            behalf shall be destroyed, purged, erased promptly within 30 days
            Business Days of upon the completion of the transaction/ End Use
            Purpose for which the Credit Information report was procured.
          </p>
          <p className="sub-heading">Governing Law and Jurisdiction</p>
          <p>
            The relationship between you and SCR&T shall be governed by laws of
            India and all claims or disputes arising there from shall be subject
            to the exclusive jurisdiction of the courts of Mumbai.
          </p>
          <p className="sub-heading">DEFINITIONS:</p>
          <p>
            Capitalised terms used herein but not defined above shall have the
            following meanings:
          </p>
          <p>
            <span className="sub-heading">“Business Day”</span> means a day
            (other than a public holiday) on which banks are open for general
            business in Mumbai.
          </p>
          <p>
            <span className="sub-heading">“CICRA” </span> shall mean the Credit
            Information Companies (Regulation) Act, 2005 read with the Credit
            Information Companies Rules, 2006 and the Credit Information
            Companies Regulations, 2006, and shall include any other rules and
            regulations prescribed thereunder.
          </p>
          <p>
            <span className="sub-heading">“Credit Information”</span> means
            collectively the credit information as defined under the CICRA, and
            includes the Credit Information Report and Credit Score;
          </p>
          <p>
            <span className="sub-heading">“Credit Information Report”</span>{" "}
            means the credit information / scores/ aggregates / variables /
            inferences or reports which shall be generated by Experian;
          </p>
          <p>
            <span className="sub-heading">“Credit Score”</span> means the score
            which shall be mentioned on the Credit Information Report which
            shall be computed by Experian.
          </p>
          <p>
            <span className="sub-heading">“End Use Purpose”</span> means use of
            the Credit Information being provided by Experian to SCR&T in the
            capacity of your authorized representative for the purpose of
            providing financial services including provisioning of personal
            loans, business loans, and other services from banks, financial
            institutions and other service providers, and assessing financial
            position and determining the financial credibility, and to use such
            Credit Information for any related purposes, including for creating
            derivative works of the same, pursuant to the User's consent (which
            consent shall be obtained by means of a click-wrap agreement and OTP
            verification) and based on the agreement/understanding to be entered
            into between SCR&T and the User.
          </p>
          <p>
            PLEASE READ THE ABOVE MENTIONED TERMS AND CONDITIONS AND CLICK ON
            “ACCEPT” FOLLOWED BY THE LINK BELOW TO COMPLETE THE AUTHORISATION
            PROCESS/ SUBMIT YOUR SELF ATTESTED KYC DOCUMENTATION IN PHYSICAL
            COPIES, IF APPLICABLE, FOR SHARING OF YOUR CREDIT INFORMATION BY
            EXPERIAN WITH SCR&T IN ITS CAPACITY AS YOUR AUTHORISED
            REPRESENTATIVE.
          </p>
          <p>
            BY CLICKING “ACCEPT” YOU AGREE AND ACCEPT THE DISCLAIMERS AND TERMS
            AND CONDITIONS SET OUT HEREIN.
          </p>
          <p>&#60;OR KYC DOCUMENTS ATTACHED ARE AS UNDER:</p>
          <p>SIGNED & EXECUTED</p>
          <p className="sub-heading">NAME</p>
          <p className="sub-heading">ADDRESS</p>
          <p className="sub-heading">DATE&#62;</p>
          <p>
            ** This document is an electronic record in terms of the Information
            Technology Act, 2000 and Rules made there under, and the amended
            provisions pertaining to electronic records.
          </p>
        </div>

        <div className="text-center">
          <p className="sub-heading">ANNEXURE-2</p>
          <p className="sub-heading">Format for Enterprise User Consent</p>
          <p className="sub-heading">
            CONSENT IN RELATION TO ACCESS TO CREDIT INFORMATION THROUGH EXPERIAN
          </p>
        </div>
        <div className="annexture-content">
          <p>
            This Consent Agreement (the{" "}
            <span className="sub-heading">“Agreement”</span>) is granted by
            &#60;Entity Name&#62; (the{" "}
            <span className="sub-heading">“User”</span> which term shall include
            its successors and permitted assigns)) a &#60;Legal
            Constitution&#62; having its registered office at
            ________________________________________ to Experian Credit
            Information Company of India Private Limited authorizing Standard
            Chartered Research and Technology India Private Limited, a private
            limited company having its registered office at 2nd Floor, Indiqube
            Edge, Khata No.571/630/6/4, Ambalipura Village, Varthur Hobli,
            Mahadevpura Div Bengaluru, Karnataka - 560102, India (
            <span className="sub-heading">“Authorised Representative”</span>{" "}
            which term shall include its successors and permitted assigns) to
            receive Credit Information on its behalf. The User and Authorised
            Representative shall be collectively referred to as the{" "}
            <span className="sub-heading">“Parties”</span> and individually as a{" "}
            <span className="sub-heading">“Party”</span>.
          </p>
          <p>
            The User hereby consents to Standard Chartered Research and
            Technology India Private Limited being appointed as authorised
            representative to receive the Users Credit Information from Experian
            for the End Use Purpose (as defined below).
          </p>
          <p className="sub-heading">
            BY EXECUTING THIS AGREEMENT/ CONSENT FORM, THE USER HEREBY EXPRESSLY
            AGREES TO ACCESS THE EXPERIAN CREDIT INFORMATION REPORT AND CREDIT
            SCORE, AGGREGATE SCORES, INFERENCES, REFERENCES AND DETAILS (AS
            DEFINED BELOW) (TOGETHER REFERRED AS “CREDIT INFORMATION”).
          </p>
          <p className="sub-heading">
            THE USER HEREBY ALSO IRREVOCABLY AND UNCONDITIONALLY CONSENTS TO
            SUCH CREDIT INFORMATION BEING PROVIDED BY EXPERIAN TO THE USER AND
            AUTHORISED REPRESENTATIVE BY USING EXPERIAN TOOLS, ALGORITHMS AND
            DEVICES AND THE USER HEREBY AGREES, ACKNOWLEDGES AND ACCEPTS THE
            TERMS AND CONDITIONS SET FORTH HEREIN.
          </p>
          <p className="sub-heading">Terms and Conditions:</p>
          <p className="sub-heading">
            Information Collection, Use, Confidentiality, No-Disclosure and Data
            Purging
          </p>
          <p>
            The Authorised Representative shall access the Users Credit
            Information as the User’s authorized representative and the
            Authorised Representative shall use the Credit Information for the
            limited End Use Purpose consisting of and in relation to the
            services proposed to be availed by the User from the Authorised
            Representative.
          </p>
          <p>
            The Parties agree to protect and keep confidential the Credit
            Information both online and offline. The Credit Information shared
            by the User, or received by the Authorised Representative, shall be
            destroyed, purged, erased promptly within 30 Business Days of upon
            the completion of the transaction/ End Use Purpose for which the
            Credit Information report was procured.
          </p>
          <p className="sub-heading">Governing Law and Jurisdiction</p>
          <p>
            The relationship between the User and the Authorised Representative
            for the purposes of this consent agreement shall be governed by laws
            of India and all claims or disputes arising there from shall be
            subject to the exclusive jurisdiction of the courts of Mumbai.
          </p>
          <p className="sub-heading">Definitions:</p>
          <p>
            Capitalised terms used herein but not defined above shall have the
            following meanings:
          </p>
          <p>
            <span className="sub-heading">“Business Day”</span> means a day
            (other than a public holiday) on which banks are open for general
            business in Mumbai.
          </p>
          <p>
            <span className="sub-heading">“Credit Information”</span> means
            collectively the credit information as defined under the CICRA, and
            includes the Credit Information Report and Credit Score.
          </p>
          <p>
            <span className="sub-heading">“Credit Information Report”</span>{" "}
            means the credit information / scores/ aggregates / variables /
            inferences or reports which shall be generated by Experian.
          </p>
          <p>
            <span className="sub-heading">“Credit Score”</span> means the score
            which shall be mentioned on the Credit Information Report which
            shall be computed by Experian.
          </p>
          <p>
            <span className="sub-heading">“CICRA” </span> shall mean the Credit
            Information Companies (Regulation) Act, 2005 read with the Credit
            Information Companies Rules, 2006 and the Credit Information
            Companies Regulations, 2006, and shall include any other rules and
            regulations prescribed thereunder.
          </p>
          <p>
            <span className="sub-heading">“End Use Purpose”</span> means use of
            the Credit Information being provided by Experian to SCR&T in the
            capacity of your authorized representative for the purpose of
            providing financial services including provisioning of personal
            loans, business loans, and other services from banks, financial
            institutions and other service providers, and assessing financial
            position and determining the financial credibility, and to use such
            Credit Information for any related purposes, including for creating
            derivative works of the same, pursuant to the User's consent (which
            consent shall be obtained by means of a click-wrap agreement and OTP
            verification) and based on the agreement/understanding to be entered
            into between SCR&T and the User.
          </p>
          <p>
            PLEASE READ THE ABOVE MENTIONED TERMS AND CONDITIONS AND CLICK ON
            “ACCEPT” FOLLOWED BY THE LINK BELOW TO COMPLETE THE AUTHORISATION
            PROCESS/ &#60;SUBMIT YOUR SELF ATTESTED KYC DOCUMENTATION IN
            PHYSICAL COPIES, IF APPLICABLE,&#62; FOR SHARING OF YOUR CREDIT
            INFORMATION BY EXPERIAN WITH SCR&T IN ITS CAPACITY AS YOUR
            AUTHORISED REPRESENTATIVE.
          </p>
          <p>
            BY CLICKING “ACCEPT” YOU AGREE AND ACCEPT THE DISCLAIMERS AND TERMS
            AND CONDITIONS SET OUT HEREIN.
          </p>
          <p>
            ** This document is an electronic record in terms of the Information
            Technology Act, 2000 and Rules made there under, and the amended
            provisions pertaining to electronic records.
          </p>
        </div>

        <div className="text-center">
          <p className="sub-heading">
            <u>ANNEXURE A</u>
          </p>
          <p className="sub-heading">Tick the Category Applicable</p>
        </div>
        <div>
          <table style={{ width: "100%;", marginTop: "2vw" }}>
            <thead>
              <tr>
                <th>
                Category &nbsp;<br></br>
                </th>
                <th>
                Nature of the Entity&nbsp;<br></br>
                </th>
                <th>
                KYC Documents &nbsp;<br></br>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: "20.0000%;" }}>
                  A<br></br>
                </td>
                <td style={{ width: "30.0000%;" }}>
                  <ul >
                    <li>Private Limited Company</li>
                    <li>Public Limited Company</li>
                    <li>One Person Company</li>
                    <li>Limited Liability Partnership</li>
                  </ul>
                </td>
                <td style={{ width: "50.0000%;" }}>
                  <ul >
                    <li>
                      Certificate of Incorporation issued by Registrar of
                      Companies (M)
                    </li>
                    <li>PAN of the entity (M)</li>
                  </ul>
                  <br></br>
                </td>
              </tr>
              <tr>
                <td style={{ width: "20.0000%;" }}>
                  B<br></br>
                </td>
                <td style={{ width: "30.0000%;" }}>
                  Registered Partnership&nbsp;
                </td>
                <td style={{ width: "50.0000%;" }}>
                  <ul >
                    <li>Registered Partnership Deed (M)</li>
                    <li>PAN Card of Entity (M)</li>
                    <li>Shops and Establishment Certificate (M)</li>
                    <li>GST certificate with all three pages</li>
                    <li>PAN Card of Partners</li>
                  </ul>
                  <br></br>
                </td>
              </tr>
              <tr>
                <td style={{ width: "20.0000%;" }}>
                  C<br></br>
                </td>
                <td style={{ width: "30.0000%;" }}>Sole Proprietorship</td>
                <td style={{ width: "50.0000%;" }}>
                  <ul>
                    <li>
                    PAN Card of Sole Proprietor (M)
                    </li>
                    <li>
                    Shops and Establishment Certificate (M)
                    </li>
                    <li>
                    GST certificate with all three pages
                    </li>
                  </ul>
            
                </td>
              </tr>
              <tr>
                <td style={{ width: "20.0000%;" }}>
                  D<br></br>
                </td>
                <td style={{ width: "30.0000%;" }}>Co-operative Society</td>
                <td style={{ width: "50.0000%;" }}>
                  <ul >
                    <li>
                      Certificate of Registration by Registrar of Societies (M)
                    </li>
                    <li>Shops and Establishment Certificate (M)</li>
                  </ul>
                  &nbsp;<br></br>
                </td>
              </tr>
              <tr>
                <td style={{ width: "20.0000%;" }}>
                  E<br></br>
                </td>
                <td style={{ width: "30.0000%;" }}>Hindu Undivided Family</td>
                <td style={{ width: "50.0000%;" }}>
                  <ul>
                    <li>
                    HUF PAN Card (M)
                    </li>
                    <li>
                    Karta PAN Card and Address Proof (M)
                    </li>
                  </ul>
                
                </td>
              </tr>
             
              <tr>
                <td colSpan={3}>
                <p>
            Note: The signing authorities should be as following for the
            referred entities
          </p>
          <p>Category A – Directors/Partners</p>
          <p>Category B – Partners</p>
          <p>Category C – Sole Proprietor</p>
          <p>Category D- Member of the Managing Body</p>
          <p>Category E – Karta</p>
          <p className="footer-text">
            Where the Authorized representative is a specific individual
            authorised to receive such information on behalf of such an entity,
            Authority Letter and KYC of the specific individual will also need
            to be affixed (ID Proof, Address Proof and PAN Card).
          </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="text-center">
          <p className="sub-heading">
            <u>ANNEXURE B</u>
          </p>
          <p className="sub-heading">Commerce Offering</p>
        </div>
        <div>
          <table style={{ width: "100%;", marginTop: "2vw" }}>
            <thead>
              <tr>
                <th>
                  Nature of the Entity&nbsp;<br></br>
                </th>
                <th>
                  Documents<br></br>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: "30.0000%;" }}>
                  Company/LLP<br></br>
                </td>
                <td style={{ width: "70.0000%;" }}>
                  <ul >
                    <li>Business PAN</li>
                    <li>GST certificate with all three pages
</li>
                    <li>
                    Valid business proof such as shops and establishment certificate, FSSAI registration, trade certificate/license electricity bill in the name of the company/LLP, telephone bill in the name of the company LLP, Gas connection book in the name of company/LLP, labour license certificate, property tax bills in the name of company/LLP, GHMC provisional license, weight scale measurement certificate.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td style={{ width: "30.0000%;" }}>
                  Sole Proprietorship/Partnership&nbsp;<br></br>
                </td>
                <td style={{ width: "70.0000%;" }}>
                  <ul >
                    <li>PAN of the entity and owner/partners</li>
                    <li>GST certificate with all three pages</li>
                    <li>
                    Valid business proof such as shops and establishment certificate, FSSAI registration, trade certificate/license, labour license certificate, property tax bills in the name of partnership, electricity bill in the name of the partnership, telephone bill in the name of the partnership, Gas connection book in the partnership, GHMC provisional license, weight scale measurement certificate.
                    </li>
                    <li>
                      Address proof such as partnership certificate, certificate
                      of registration
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="text-center">
          <p>Financial Services</p>
        </div>
        <div>
          <table style={{ width: "100%;", marginTop: "2vw" }}>
            <thead>
              <tr>
                <th>
                  Nature of the Entity&nbsp;<br></br>
                </th>
                <th>
                  Documents&nbsp;<br></br>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: "30.0000%;" }}>
                Company/LLP&nbsp;<br></br>
                </td>
                <td style={{ width: "70.0000%;" }}>
                  <ul >
                    <li>Business PAN</li>
                    <li>Credit information report</li>
                    <li>GST report</li>
                    <li>Bank statement</li>
                    <li>GST certificate</li>
                    <li>Shops and establishment certificate</li>
                    <li>
                      Address proof such as partnership certificate, certificate
                      of registration
                    </li>
                    <li>
                      Invoice and related transaction data including logistics
                      information
                    </li>
                    <li>
                    Operational address proof
                    </li>
                    <li>
                    Certificate/registration used by a regulatory/statutory body
                    </li>
                    <li>
                    Utility Bill, if required
                    </li>
                    <li>
                    Income Tax Return for the preceding two years;
                    </li>
                    <li>
                    Bank statements for the last 12 months.
                    </li>
                    <li>
                    	Audited financial statements
                    </li>
                    <li>
                    Commercial bureau report
                    </li>
                    <li>
                    Any other document required by Third Party Service Provider
                    </li>
                  </ul>
                  <p>
                  From the owner:  
                  </p>
                  <ul>
                    <li>
                    	a certified copy of any OVD containing details of his identity and address 
                    </li>
                    <li>
                    	one recent photograph 
                    </li>
                    <li>
                    	PAN; OR offline verification
                    </li>
                    <li>
                    	Credit report
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td style={{ width: "30.0000%;" }}>
                  Sole Proprietorship/&nbsp;<br></br>Partnership
                </td>
                <td style={{ width: "70.0000%;" }}>
                  <ul >
                    <li>
                    	PAN of the owner
                    </li>
                    <li>
                    	Credit information report 
                    </li>
                    <li>
                      GST report
                    </li>
                    <li>
                      Bank statement 
                    </li>
                    <li>
                    	GST certificate
                    </li>
                    <li>
                    	Shops and establishment certificate
                    </li>
                    <li>
                    Address proof such as partnership certificate, certificate of registration
                    </li>
                    <li>
                    	Invoice and related transaction data including logistics information
                    </li>
                    <li>Operational address proof</li>
                    <li>Certificate/registration used by a regulatory/statutory body</li>
                    <li>Utility Bill, if required</li>
                    <li>Income Tax Return for the preceding two years;</li>
                    <li>Bank statements for the last 12 months.</li>
                    <li>Audited financial statements</li>
                    <li>Commercial bureau report</li>
                    <li>
                    Any other document required by Third Party Service Provider
                    </li>
                  </ul>
                  <p>
                  From the owner:
                  </p>
                  <ul>
                    <li>
                    a certified copy of any OVD containing details of his identity and address 
                    </li>
                    <li>
                    one recent photograph 
                    </li>
                    <li>
                    PAN; OR offline verification
                    </li>
                    <li>
                    Credit report
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      
        <div className="text-center">
          <p className="sub-heading">
            <u>ANNEXURE C</u>
          </p>
          <p className="sub-heading">
            RETURN SHIPMENTS POLICY
          </p>
        </div>
        <div className="annexture-content">
          <p> Please refer our Return Shipments Policy available at <a
            className="anchor-link"
            href="https://www.solvezy.com/return-shipment-policy/"
          >
            https://www.solvezy.com/return-shipment-policy/
          </a>{" "} which forms part of these terms.
          
          </p>
        </div>
        {/* <div className="main-content">
          <p>
            This Return Shipments Policy (<strong>&quot;Policy&quot;</strong>)
            governs the return request raised by the Buyer in accordance with
            the terms of this Policy (&quot;<strong>Return Request</strong>
            &quot;) and the mechanism for resolution of disputes raised by the
            Seller in relation to the Return Requests.
          </p>
          <p>
            We may modify this Policy from time to time, and any such changes
            will be reflected on the Platform and be effective immediately upon
            the changes being reflected on the Platform. Users agree to be bound
            to any such changes or modifications and understand and accept the
            importance of regularly reviewing the Policy as updated on the
            Platform from time to time.
          </p>
          <p>
            <strong>A. RETURN REQUEST</strong>
          </p>
          <ol>
            <li>
              The buyer can raise a return request through any of the following
              channels&nbsp;
              <ol type="a">
                <li>
                  Raise a return request on the Solv App&nbsp;
                </li>
                <li>
                  Chat with live agent on Solv App via Help section in the main
                  menu&nbsp;
                </li>
                <li>
                  <a className="mail-anchor" href="mailto:cs@solvezy.com">
                    Write to{" "}
                    <span style={{ textDecoration: "underline" }}>
                      cs@solvezy.com
                    </span>{" "}
                    &nbsp;
                  </a>
                </li>
                <li>
                  Call on customer care number 9108191081&nbsp;
                </li>
                <li>
                  Send a WhatsApp message on customer care number
                  9108191081&nbsp;
                </li>
              </ol>
            </li>
            <li>
              The Buyer may raise a Return Request on the Platform for any one
              of the following reasons:
              <ol type="a">
                <li>
                  Product(s) is physically damaged or is defective, including
                  damage to the outer box delivered.&nbsp;
                </li>
                <li>
                  Warranty issues or quality related issues with respect to the
                  Product delivered.
                </li>
                <li>
                  Wrong Product or the Product is not matching the description
                  or specifications mentioned on the listing page on the
                  Platform.
                </li>
                <li>
                  Part of the Order/ Products is found to be missing due to
                  reasons attributable to the Seller.
                </li>
                <li>Product was damaged in-transit; or&nbsp;</li>
                <li>Any other product related issues. &nbsp;</li>
              </ol>
            </li>
            <li>
              Following points are to be considered while placing return
              request:
              <ol type="i">
                <li>
                  Product shall be returned in same brand packaging in which
                  it was delivered.
                </li>
                <li>
                  In case a damaged outer packaging is received, the same should
                  be mentioned as a remark on the proof of delivery&nbsp;
                </li>
                <li>
                  Products requested to be returned shall be stored
                  separately so that no one can use them.
                </li>
                <li>
                  Ensure all products in a particular invoice are handed over to
                  pickup partner as no partial return policy will be applicable.
                </li>
                <li>
                  In case of an order for oil, same should be returned
                  immediately if any damage/shortage is observed, once accepted
                  by buyer, it will not be considered for return.
                </li>
                <li>
                  Notwithstanding anything mentioned above, return request for consumer electronic products will be
                  accepted only in case of the following :&nbsp;
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      Disparity in the number of products/outer packaging ordered &amp;
                      received.&nbsp;
                    </li>
                    <li>Outer packaging being damaged.</li>
                    <li>Wrong product sent by the seller.</li>
                  </ol>
                </li>
              </ol>
              <p style={{ "text-align": "left;" }}>
                <strong>Note</strong>: Under no circumstances, Solv will bear for
                any losses including those listed under vi a,b,c. Solv will
                only coordinate with the seller.&nbsp;
              </p>
            </li>
            <li>
              Return Requests shall not be processed in case of the following
              reasons:
              <ol type="i">
                <li style={{ "text-align": "left;" }}>
                  In case brand packaging is changed&nbsp;
                </li>
                <li style={{ "text-align": "left;" }}>
                  If there are no remarks on the proof of delivery about a
                  damaged outer packaging
                </li>
                <li style={{ "text-align": "left;" }}>
                  Return request shall be processed for full return only, no
                  partial return request is accepted
                </li>
                <li style={{ "text-align": "left;" }}>
                  Return requests will be rejected in case a product being
                  returned is found to be used
                </li>
                <li style={{ "text-align": "left;" }}>
                  Return request for oil shall not be processed once the buyer
                  accepts the shipment
                </li>
                <li style={{ "text-align": "left;" }}>
                  For Consumer Electronics: Returns cannot be processed for the
                  following reasons:
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>Customer did not like the product</li>
                    <li>Product is available at better price somewhere else</li>
                    <li>Customer doesn't need the product any more</li>
                    <li>
                      Any reason excluding disparity in the number of items
                      ordered &amp; received, product defect or damage,
                      incorrect material received.
                    </li>
                  </ol>
                </li>
              </ol>
              <p style={{ marginTop: "2vw" }}>
                In any of the above cases, the return request will be rejected
                by the customer care team&nbsp;
              </p>
            </li>
            <li>
              At the time of making a return request on the platform, Buyer will
              be required to provide appropriate supporting documentations/
              proofs as mentioned below:
              <ol>
                <li style={{ "text-align": "left;" }}>
                  Images of the product(s) indicating the issue in the product/
                  shipment delivered. The images need to capture the
                  following:&nbsp;
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>issue observed by the buyer in the product;</li>
                    <li>damages to the product; and/or</li>
                    <li>
                      the IMEI number (in case the Product is a mobile phone);
                    </li>
                  </ol>
                </li>

                <li style={{ "text-align": "left;" }}>
                  Unboxing/normal video clip indicating the issues in the
                  product/ shipment delivered. The video clip needs to capture
                  all sides of the package, order details, shipping label,
                  quantity of the product, damages in the product, IMEI number
                  (in case of mobile phones), any tampering with the outer box
                  etc.
                </li>
                <li style={{ "text-align": "left;" }}>
                  For Consumer Electronics: It is mandatory to shoot an unboxing
                  video for the buyer and the seller and the same must be submitted while
                  raising a return request. No return will be entertained if unboxing video is not provided.
                </li>
                <li style={{ "text-align": "left;" }}>
                  Copy of the bill/ tax invoice for the product received.
                </li>
              </ol>
              <p style={{ marginTop: "2vw" }}>
                <strong>Note</strong>: In case of any dispute observed for
                Consumer Electronics whenever return case is forwarded to
                Seller, they should provide initial packing video as well as
                unboxing video upon receipt of any returned product from
                buyer.&nbsp;
              </p>
              <p style={{ marginTop: "2vw" }}>
                If any further information or clarification is required from the
                Buyer, we will contact them.&nbsp;
              </p>
            </li>
            <li>
              Depending on the category of the product, the Buyer may raise a
              Return Request for any of the issues as set forth under paragraph
              A.2 above within the timelines prescribed below. In case the
              request is raised beyond this window, the return/ refund will not
              be done.
            </li>

            <table style={{ width: "100%;", marginTop: "2vw" }}>
              <thead>
                <tr>
                  <th>
                    Category&nbsp;<br></br>
                  </th>
                  <th>
                    Timelines&nbsp;<br></br>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ width: "50.0000%;" }}>
                    FMCG*&nbsp;<br></br>
                  </td>
                  <td style={{ width: "50.0000%;" }}>
                    Delivery Date +1&nbsp;<br></br>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50.0000%;" }}>
                    HoReCa*&nbsp;<br></br>
                  </td>
                  <td style={{ width: "50.0000%;" }}>
                    Delivery Date +1&nbsp;<br></br>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50.0000%;" }}>
                    Apparel&nbsp;<br></br>
                  </td>
                  <td style={{ width: "50.0000%;" }}>
                    Delivery Date +5<br></br>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50.0000%;" }}>
                    Consumer Electronics&nbsp;<br></br>
                  </td>
                  <td style={{ width: "50.0000%;" }}>
                    Delivery Date +2&nbsp;<br></br>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50.0000%;" }}>
                    Home Furnishing&nbsp;<br></br>
                  </td>
                  <td style={{ width: "50.0000%;" }}>Delivery Date +5</td>
                </tr>
                <tr>
                  <td style={{ width: "50.0000%;" }}>
                    Footwear&nbsp;<br></br>
                  </td>
                  <td style={{ width: "50.0000%;" }}>Delivery Date +5</td>
                </tr>
                <tr>
                  <td style={{ width: "50.0000%;" }}>
                  Toys and Sports&nbsp;<br></br>
                  </td>
                  <td style={{ width: "50.0000%;" }}>Delivery Date +5</td>
                </tr>
              </tbody>
            </table>
            <p style={{ marginTop: "2vw" }}>
              * Except oil products which need to be returned on the same day,
              immediately upon delivery
            </p>
            <p style={{ marginTop: "2vw" }}>
              We will internally scrutinize the return request raised by the
              Buyer and notify the Seller. The Return Request raised by the
              Buyer will either be approved or rejected and the same will be
              communicated to the Buyer.
            </p>
          </ol>

          <p>
            <strong>B. DECISION ON THE RETURN REQUESTS</strong>
          </p>
          <p>
            Basis our internal scrutiny of the Return Request raised by the
            Buyer, any of the following may occur:
          </p>
          <ol>
            <li>
              <strong>Approval of the Return Request</strong>: The Return
              Request will be processed in case it has been approved by the
              Seller. In such a case, the reverse shipment will be shipped to
              the seller. In case seller fails to respond within defined TAT as
              defined by us from the time of escalation same shall be considered
              as approved from seller for return.
              <br></br>or
              <br></br>
              In case of approval of the return request if the buyer has
              already paid for the order, the order amount will be refunded to
              the buyer&rsquo;s payment source (Bank Account as updated on the
              Solv App / Credit Card, Etc.) within 15 days for intracity return
              requests and 45 days for intercity return requests, from the date
              of the return request being raised along with all required
              documentations.
              <p style={{ "text-align": "left;", marginTop: "2vw" }}>
                <strong>Note</strong>: In case the buyer has utilized Pay Later
                services, the amount shall be refunded to the Financial
                Institution, and it will be adjusted with the current
                outstanding amount before the due date and if the refund happens
                after the due date, the amount shall be marked as excess and
                will be adjusted during next billing.&nbsp;
              </p>
            </li>
            <li>
              {" "}
              <strong>Rejection of the Return Request</strong>: The Return
              Request raised by the Buyer shall be rejected, in case of any of
              the following reasons:
              <ol>
                <li>
                  If the Buyer does not respond to the inquiry calls and/or
                  communication via other modes made by us to procure missing
                  documentation and information in relation to the Return
                  Request; or
                </li>
                <li>
                  supportive documents are insufficient, or Buyer is unable to
                  provide sufficient proof in support of the claim.
                </li>
              </ol>
            </li>
            <li>
              <strong>Exceptional cases</strong>: In exceptional cases such as
              fraud, deficiency in service, or any other circumstance which may
              affect the user experience on the Platform, we may in good faith
              and in our sole discretion decide to pay the transaction amount
              for the disputed product in the absence of Seller's approval of
              such returns.
            </li>
            <li>
              In all the above-mentioned cases wherein, the return requests are
              approved by the Seller, refund will be initiated to the Buyer post
              the product pickup from the buyer.
            </li>
          </ol>
        </div> */}
        {/* <div>
        <p className="sub-heading">
            Detailed Guidelines on the Policy is given below:
          </p>
          <p>
          Guidelines for hassle free return experience
          </p>
          <p>
          	If short / damaged material received
            <ul>
              <li>
              Ensure to check box for any damages at the time of delivery. 
              </li>
              <li>
              	Ensure to count number of boxes received against number of boxes mentioned in shipping label pasted on the box.
              </li>
              <li>
              	In case the received box/material is damaged/short, ensure to mention remarks on Proof of Delivery (POD) while accepting material. (In case the Delivery Service Partner (DSP) is not allowing, then please don’t accept the delivery).
                </li>
                <li>
                	Ensure that the unpacking video is captured and ensure that the below mentioned checks are included as per sample video link attached (Please try to use good resolution camera for better clarity):
                  <ol>
                    <li>
                    	Shipping label should be visible clearly.
                    </li>
                    <li>
                    All sides of boxes should be captured in the video.
                    </li>
                    <li>
                    	Damage to the box/product should be clearly visible
                    </li>
                    <li>
                    	Counting of products should be in front of camera & should be visible clearly.
                    </li>
                    <li>
                    	There should be only one single video from unpacking till counting of products.
                    </li>
                    <li>
                    	In case of mobiles, IMEI number label should be visible in video.
                    </li>
                    <li>
                    	In case of consumer electronics, it is mandatory to shoot an unboxing video.
                    </li>
                    <li>
                    	Invoice scanned copy should be available with buyer.
                    </li>
                  </ol>
                </li>
                <li>
                	Ensure to raise complaint with proofs within 24 hours of material receipt.
                </li>
            </ul>
          </p>
          <p>
          Any order where buyer wants to return the product for any reason other than short shipment/ Damaged material: 
          </p>
          <ul>
            <li>
            Ensure to raise complaint, along with proofs as per the timelines referred in point 6 above .:
            </li>
          	
            <li>
            Ensure to keep proofs ready while raising complaint and submit the same within 48 hours once complained is raised. Below is the list of proofs required:
                
          <ol>
            <li>
            	Images of products highlighting the issue (Applicable for return requested against product quality).
            </li>
            <li>
            	Video of unboxing in which shipping label, all sides of box, counting of products, IMEI number (For mobiles) should be captured.
              </li>
              <li>
              	Invoice copy.
</li>
<li>	Packing video of the same box which needs to be handed over to courier for return which should capture shipping label & counting of products while packing the box.
  </li>
  <li>
  	AWB number should be clearly visible, which is pasted on the box.
</li>
<li>
	Packing & Unboxing video should be in a single flow. No multiple videos are acceptable.   
  </li>
          </ol>
            </li>
<li>
	Ensure to count number of boxes received against number of boxes mentioned in shipping label pasted on the box.
</li>
<li>
	In case the received box/material is damaged/short ensure to mention remarks on POD while accepting material. (In case DSP is not allowing, then please don’t accept the delivery).
</li>
<li>
Ensure that same packaging is used while returning shipments which is received from seller.
</li>
<li>
	Ensure complete products are handed over to courier person, no partial return will be accepted.
</li>
<li>
	Buyers to ensure correct box to be handover to the courier & receipt should be collected from them in case any courier is not giving any receipt then don’t handover material to courier.
</li>
<li>
Please ensure that your active bank account details are available on app.
</li>
          </ul>
    <p>
      <strong>
      Note: 
      </strong>
<ol>
<li>
	In case of valid proofs not submitted within 48 hours in that case return request will not be considered.
</li>
<li>
	The return request of the Buyer shall be rejected due to the following reasons that:
  <p>
  (a) Buyer does not respond to the inquiry calls and/or calls made by us to procure missing documentation and information in relation to such return request; 
  </p>
<p>
(b) Supportive documents are insufficient, or Buyer is unable to provide sufficient proof in support of the claim.
</p>


</li>
</ol>

      </p> 


        </div> */}
        <div className="text-center">
          <p className="sub-heading">
            <u>ANNEXURE D</u>
          </p>
          <p className="sub-heading">
            POLICY ON SALE OF ILLEGAL AND RESTRICTED PRODUCTS AND SERVICES ON
            SOLV PLATFORM
          </p>
        </div>
        <div className="annexture-content">
          <p className="sub-heading">PURPOSE</p>
          <p>
            As a responsible platform user, you shall not, directly or
            indirectly, sell, offer, attempt to offer, trade or attempt to trade
            in any product or service, through the Solv platform or using the
            Platform, the dealing of which is prohibited or restricted in any
            manner under the provisions of any applicable law, rule, regulation
            or guideline for the time being in force.
          </p>
          <p className="sub-heading">SCOPE</p>
          <p>
            Products and services offered for sale on Solv platform must comply
            with all laws and regulations and with Solv’s policies. The sale of
            illegal, unsafe, or other restricted products listed on these pages,
            including any pharmaceutical products available only by
            prescription, is strictly prohibited.
          </p>
          <p className="sub-heading">POLICY ELEMENTS</p>
          <p>
            If you intend to supply, display or offer to sell any products or
            services through Solv or its platform, you should carefully review
            the Restricted Products listed below before listing a product. The
            examples provided on the below pages are not all-inclusive and are
            provided solely as an informational guide.
          </p>
          <p>
            We encourage you to consult with your legal counsel if you have
            questions about the laws and regulations concerning your products or
            you are unsure whether the products or services area classified as
            Restricted Products. Even where a product is included within
            “Permitted Listings," all products and listings must also comply
            with applicable laws.
          </p>
          <p>
            If you supply or offer to supply a product or service in violation
            of the law or any of Solv’s policies or it has been brought to our
            notice by any other platform user or governmental authority that any
            product or service is in violation of law or any of Solv’s policies,
            including those listed as Restricted Products below, we will take
            corrective actions, as appropriate, including but not limited to
            removing the product or service from the platform, immediately
            suspending or terminating selling privileges, returning inventory,
            terminating the business relationship, and permanent withholding of
            payments. The sale of illegal or unsafe products through Solv
            platform can also lead to legal action, including civil and criminal
            penalties. You will indemnify Solv for any loss, damage, claim,
            action, costs, charges and expenses which Solv may suffer or incur
            as a result of your actions or breach of this policy.
          </p>
          <p>
            Further, we are constantly innovating and working with third party
            experts, vendors, and sellers to improve the ways we detect and
            prevent illegal and unsafe products from reaching our marketplace.
          </p>
          <p>
            Solv encourages you to report listings that violate Solv’s policies
            or applicable law by sending an email on cs@solvezy.com. We will
            investigate each report thoroughly and take appropriate action.
          </p>
          <p className="sub-heading">LIST OF RESTRICTED/PROHIBITED PRODUCTS</p>
          <p>
            Without prejudice to the generality of the above, SOLV does not
            permit hosting of following items.
            <ul className="liparent" style={{ listStyleType: "decimal" }}>
              <li>
                “Securities” within the meaning of the Securities Contract
                Regulation Act, 1956, including shares, bonds, debentures, etc.
                and/or any other financial instruments/assets of any
                description.
              </li>
              <li>
                Living, dead creatures and/or the whole or any part of any
                animal which has been kept or preserved by any means whether
                artificial or natural including rugs, skins, specimens of
                animals, antlers, horns, hair, feathers, nails, teeth, musk,
                eggs, nests, other animal products of any description the sale
                and purchase of which is prevented or restricted in any manner
                by applicable laws (including those prohibited under The
                Wildlife Protection Act, 1972).
              </li>
              <li>
                Weapons which includes firearms, ammunition, knives, brass
                knuckles, gun parts, and other armaments.
              </li>
              <li>
                Psychotropic substances, narcotics, intoxicants of any
                description, medicines, palliative/curative substances.
              </li>
              <li>
                Tobacco and cigarettes which includes cigarettes, cigars,
                chewing tobacco, and related products.
              </li>
              <li>
                Alcohol which includes Alcohol or alcoholic beverages such as
                beer, liquor, wine, or champagne.
              </li>
              <li>
                Drugs and drug paraphernalia which includes illegal drugs and
                drug accessories, including herbal drugs like salvia and magic
                mushrooms.
              </li>
              <li>
                Prescription drugs or herbal drugs or any kind of online
                pharmacies which includes drugs or other products requiring a
                prescription by a licensed medical Practitioner.
              </li>
              <li>
                Religious items, including books, artifacts, etc. of any
                description or any other such item which is likely to affect the
                religious sentiments of any person.
              </li>
              <li>
                “Antiquities” and “Art Treasures” in violation of the provisions
                of the Antiquities and Art Treasures Act, 1972 (“the Act”).
              </li>
              <li>
                Furthermore, you agree to display and adhere to a term of use or
                other user-type agreement, as well as a privacy policy,
                governing your operation of Your Store and Your conduct with
                Your Store’s customers.
              </li>
              <li>
                Adult goods and services and other sexually suggestive materials
                (including literature, imagery and other media); escort or
                prostitution services ; Website access and/or website
                memberships of pornography or illegal sites.
              </li>
              <li>Body parts which includes organs or other body parts.</li>
              <li>
                Child pornography which includes pornographic materials
                involving minors.
              </li>
              <li>
                Copyrighted media which includes unauthorized copies of books,
                music, movies, and other licensed or protected materials.
              </li>
              <li>
                Copyrighted software which includes unauthorized copies of
                software, video games and other licensed or protected materials,
                including OEM or bundled Software.
              </li>
              <li>
                Counterfeit and unauthorized goods which includes replicas or
                imitations of designer goods; items without a celebrity
                endorsement that would normally require such an association;
                fake autographs, counterfeit stamps, and other potentially
                unauthorized goods.
              </li>
              <li>
                Endangered species which includes plants, animals or other
                organisms (including product derivatives) in danger of
                extinction.
              </li>
              <li>
                Gaming/gambling which includes lottery tickets, sports bets,
                memberships/ enrollment in online gambling sites, and related
                content.
              </li>
              <li>
                Hacking and cracking materials which includes manuals, how-to
                guides, information, or equipment enabling illegal access to
                software, servers, websites, or other protected property.
              </li>
              <li>
                Illegal goods which includes materials, products, or information
                promoting illegal goods or enabling illegal acts.
              </li>
              <li>
                Offensive goods which includes literature, products or other
                materials that – Promote intolerance or hatred / Defame or
                slander any person or groups of people based on race, ethnicity,
                national origin, religion, sex, or other factors / Encourage or
                incite violent acts.
              </li>
              <li>
                Offensive goods, crime which includes crime scene photos or
                items, such as personal belongings, associated with criminals.
              </li>
              <li>
                Pyrotechnic devices, combustibles, corrosives and hazardous
                materials which includes explosives, fireworks and related
                goods; toxic, flammable, and radioactive materials and
                substances.
              </li>
              <li>
                Regulated goods which includes air bags; batteries containing
                mercury; Freon or similar substances/refrigerants;
                chemical/industrial solvents; government uniforms; car titles;
                license plates; police badges and law enforcement equipment;
                lock-picking devices; pesticides; postage meters; recalled
                items; slot machines; surveillance equipment; goods regulated by
                government or other agency specifications.
              </li>
              <li>
                Wholesale currency which includes discounted currencies or
                currency exchanges
              </li>
              <li>
                Any product or service which is not in compliance with all
                applicable laws and regulations whether federal, state, local or
                international including the laws of India.
              </li>
              <li>
                Businesses or websites that operate within the scope of laws
                which are not absolutely clear or are ambiguous in nature (e.g.
                Web-based telephony, Websites supplying medicines or controlled
                substances, websites that promise online match-making).
              </li>
              <li>
                Businesses out rightly banned by law (e.g. Betting & Gambling/
                publications or content that is likely to be interpreted by the
                authorities as leading to moral turpitude or decadence or incite
                caste/communal tensions, lotteries/sweepstakes & games of
                chance.
              </li>
            </ul>
          </p>
        </div>
      </div>
    </>
  )
}

export default TermsOfServices
