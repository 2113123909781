import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import Slider from "react-slick"

const BannerSlickWrapper = ({page}) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        sort: { fields: name, order: DESC }
        filter: { relativeDirectory: { eq: "bg" } }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `)

  const filtereddata = data.allFile.edges.filter(ele =>
    ele.node.name.includes(page)
  )

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    fade: true,
  }
  return (
    <>
      <Slider {...settings}>
        {filtereddata.map((value, index) => {
          return (
            <Img
              key={value.node.id}
              fluid={value.node.childImageSharp.fluid}
              alt={`banner image ${index}`}
            />
          )
        })}
      </Slider>
    </>
  )
}

export default BannerSlickWrapper
