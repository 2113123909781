import React from 'react';
import "./../../../styles.scss"
import './SmartSolver.scss'
import smartSolvers from '../../../../static/smartSolvers.svg'

const SmartSolver = () => {
    return (
        <div className='smart-solver-card'>
            <div>
                <img className='smart-solver-image' src={smartSolvers} alt="smartSolver" />
            </div>
            <div className='smart-solver-content'>
                <div>
                Nothing for you at this point? Worry not! Just apply as a Random Smart Solver by emailing us at solv.ta@solvezy.com and we’ll drop you a line once we have something interesting for you.
                </div>
            </div>
        </div>
    )
}

export default SmartSolver