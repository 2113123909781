import React from "react"
import { InView } from 'react-intersection-observer';
import "./Paylater.scss"

import CheckpointRed from "../../../../static/checkpoint-red.svg"
import buyNowImg from "../../../../static/buyNowImg.png"
import fsBuyNow from "../../../../static/fsBuyNow.png"

import buynowPaylaterVid from "../../../assets/videos/BuyNow_Paylater.mp4"
import buynowPaylaterVidThumb from "../../../assets/Videos Thumbnails/BNPL.png"

const Paylater = () => {
  function playPause(id, state) {
    let vid = document.getElementById(id)

    if (state) {
      vid.play()
    } else {
      vid.pause()
    }
  }
  return (
    <div className="paylater-container">
      <div className="paylater-heading">
        <div className="section-pay-heading">Buy Now, Pay Later</div>
        <div className="section-pay-subheading">
          Short term credit for your orders
        </div>
      </div>
      <div className="paylater-content">
        <div className={["wrapper", "content-section"].join(" ")}>
          <div className={["details", "divInside"].join(" ")}>
            <span>
              Buy now, pay later is a short term, revolving credit option
              available to buyers on the Solv B2B marketplace. Buyers can avail
              the buy now, pay later credit line available to them across
              multiple invoices and take advantage of flexible payment
              schedules. Credit is available for 15 or 21 days at interest rates
              of 1% and 1.5% respectively. Don’t let liquidity challenges hold
              you back from growing your business.
            </span>
          </div>
          <div className={["paylater-checkpoints", "divInside"].join(" ")}>
            <div className="paylater-checkpoint-item">
              <img className="payCheckListImage" src={CheckpointRed} />
              <span className="checkpoint-text">Collateral Free</span>
            </div>
            <div className="paylater-checkpoint-item">
              <img className="payCheckListImage" src={CheckpointRed} />
              <span className="checkpoint-text">
                Credit limit up to Rs.2,00,000
              </span>
            </div>
            <div className="paylater-checkpoint-item">
              <img className="payCheckListImage" src={CheckpointRed} />
              <span className="checkpoint-text">No hidden fees</span>
            </div>
            <div className="paylater-checkpoint-item">
              <img className="payCheckListImage" src={CheckpointRed} />
              <span className="checkpoint-text">Easy application process</span>
            </div>
            <div className="paylater-checkpoint-item">
              <img className="payCheckListImage" src={CheckpointRed} />
              <span className="checkpoint-text">
                Quick sanction & disbursal
              </span>
            </div>
          </div>
        </div>
        <div className={["wrapper", "paylater-image-section"].join(" ")}>
          <div className="divInside">
            {/* <img className="paylater-section-image-web" src={buyNowImg} />
            <img className="paylater-section-image-mob" src={fsBuyNow} /> */}
            <video
              id="bnplVid"
              onClick={() => playPause("bnplVid")}
            //   className={styles.servicesSectionMob}
              autoPlay muted loop playsInline
              poster={buynowPaylaterVidThumb}
            >
              <source src={buynowPaylaterVid} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Paylater
