import React from 'react';
import "./../../../styles.scss"
import './JobCard.scss'

import {navigate} from 'gatsby'

import TimeAgo from 'react-timeago'

const JobCard = ({ job }) => {
    function navigateToJobPage() {
        navigate(job.slug)
    }

    const jobPostDate = new Date(job.postedDate);
    const todaysDate = new Date();
    const diffTime = Math.abs(todaysDate - jobPostDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

    const newPostedDate = new Date().setDate((new Date().getDate()) - (diffDays % 30));

    return (
        <div className='solv-job-card'>
            <div className='job-card-title-section'>
               <div className='job-flex'>
                    <div className='job-title'>{job.title}</div>
                    <div className='flex-mobile--btn'>
                        <div>
                            <div className='job-location'>{job.location} | {job.experience}</div>
                            <div className='job-update-date'>
                                <TimeAgo date={newPostedDate}/>
                            </div>
                        </div>
                        <div>
                            <div className='view-mob' onClick={() => navigateToJobPage()}>View</div>
                        </div>
                    </div>
               </div>
            </div>
            <div className='job-card-details-section'>
                <div>
                    <div className='view-button'  onClick={() => navigateToJobPage()}>
                        View details 
                    </div>
                    
                    {/* &gt */}
                </div>
            </div>
        </div>
    )
}

export default JobCard