import React from 'react';
import styles from "./coverage-item-css-module.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const CoverageItem = ({ post}) => {
    
    function onClick(url) {
        window.open(url)
      }
    
    const data = useStaticQuery(graphql`
    query {
      allFile(
        sort: { fields: name, order: DESC }
        filter: { relativeDirectory: { eq: "publications" } }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `)
    const logo = data.allFile.edges.find(ele =>
        ele.node.name.includes(post.image)
      )
    return (
        <div className={styles.coverageItem}>
            <div className={styles.imageContainer}>
                <Img
                key={logo.node.id}
                fluid={logo.node.childImageSharp.fluid}
                alt={`${post.publication} Logo`}
                />
            </div>
            <div className={styles.description}>
                {post.publication} | {post.postedDate}
            </div>
            <div className={styles.title}>
                {post.heading}
            </div>
            {post.url ? <div className={styles.hyperlink}  onClick={() => onClick(post.url)}>Read More &gt; </div> : ''}
        </div>
    )
}


export default CoverageItem