import React, { Component } from 'react';
import "./../../../styles.scss"
import './CurrentJobs.scss'
import JobCard from '../job-card/JobCard';
import SmartSolver from '../smart-solver-card/SmartSolver'
//import DragScroll from '../../common/dragScroll/DragScroll'

import jobPostsList from '../../common/DataSource/Job-Posting.json'

class CurrentJobs extends Component {

    constructor(props) {
        super(props);
        let filterList = [];
        jobPostsList.forEach((jobs) => {
            filterList = [...filterList, ...jobs.tags]
        })
        filterList = [...new Set(filterList)];
        let filterJobList = [
            {
                id: 1,
                title: `All (${jobPostsList.length})`,
                selected: true,
                jobs: jobPostsList
            }
        ];
        filterList.forEach((filter) => {
            let filteredJobs = jobPostsList.filter((job) => job.tags.indexOf(filter) > -1)
            let jobObj = {
                id: filterJobList.length + 1,
                title: `${filter} (${filteredJobs.length})`,
                selected: false,
                jobs: filteredJobs
            }
            filterJobList.push(jobObj)
        });

        this.state = {
            filter: filterJobList,
            jobPosts: jobPostsList
        }
    }

    filterSelection = (jobs, id) => {
        const filterList = this.state.filter;
        filterList.forEach(filter => {
            if (filter.id === id) {
                filter.selected = true;
            } else {
                filter.selected = false;
            }
        });
        this.setState({ filter: [...filterList], jobPosts: [...jobs] });
    }

    render() {
        return (
            <>
                <div className='current-job-section'>
                    <div className='current-job-header'>
                        {/* <div className='current-job-main-title'>Current Job Openings</div> */}
                        <div className='current-job-filter'>
                            {this.state.filter.map((filter) => (
                                <div
                                    key={filter.id}
                                    className={
                                        filter.selected ? 'filter-button selected' : 'filter-button'
                                    }
                                    onClick={() => this.filterSelection(filter.jobs, filter.id)}>
                                    {filter.title}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='current-job-content'>
                        {this.state.jobPosts.map((job) => (
                            <JobCard key={job.id} job={job} />
                        ))}
                        <SmartSolver />
                    </div>
                </div>
            </>
        )
    }
}

export default CurrentJobs