import React, { useState } from "react"
import "./testimonial.scss"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import ModalVideo from "react-modal-video"

const Testimonial = () => {
  const [isOpen, setOpen] = useState(false)
  const [videoId, setVideoId] = useState()

  let videoMap= new Map()
  videoMap.set('SRI LAKSHMI NARASIMHA RICE TRADERS', 'Nn46jzwXDHs')
  videoMap.set('SALEEM TRADERS', 'Iz96KtZ7xqQ')
  videoMap.set('RITHI SWEETS', 'PBewP7xPWeY')
  videoMap.set('RICH BITE', 'GcIsnPN6hS0')
  videoMap.set('PMS TRADERS', 'ONBOMAyJSnQ')
  videoMap.set('Ashirwad Bakery', 'mOxgRJ907jE')
  videoMap.set('ARUN TRADERS', 'Thpl6vs8XWQ')
  videoMap.set('AKSHAYAM', 'FK-nSKgd2t8')
  videoMap.set('P.KRISHNA SUPER BAZAR', 'UA0_pq6eg0s')

  const data = useStaticQuery(graphql`
    query {
      allFile(
        sort: { fields: name, order: ASC }
        filter: { relativeDirectory: { eq: "testimonial" } }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `)

  function openModal(name) {
    setVideoId(videoMap.get(name))
    setOpen(true)
  }

  return (
    <>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId={videoId}
        onClose={() => setOpen(false)}
      />
      <div className="testimonialBg">
        <div className="testimonialHeader">Hear from our customers</div>
        <div className="testimonialSubHeader">
          <div>Solv has helped thousands of small business owners across India</div>
          <div>achieve growth and realise their true potential.</div>
        </div>
        <div className="testimonialWrapper">
          {data.allFile.edges.map((value, index) => {
            return (
              <div
                key={value.node.id}
                className={`image-${index+1}`}
                onClick={() => openModal(value.node.name)}
              >
                <Img
                  fluid={value.node.childImageSharp.fluid}
                  alt={`banner image ${index}`}
                />
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default Testimonial
