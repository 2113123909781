import React from 'react'
//import icon from "../../assets/icon.png"
import career1 from "../../../static/whySolv/career1.svg"
import career2 from "../../../static/whySolv/career2.svg"
import career3 from "../../../static/whySolv/career3.svg"
import career4 from "../../../static/whySolv/career4.svg"
import career5 from "../../../static/whySolv/career5.svg"
import career6 from "../../../static/whySolv/career6.svg"

import home1 from "../../../static/whySolv/home1.svg"
import home2 from "../../../static/whySolv/home2.svg"
import home3 from "../../../static/whySolv/home3.svg"
import home4 from "../../../static/whySolv/home4.svg"
import home5 from "../../../static/whySolv/home5.svg"
import home6 from "../../../static/whySolv/home6.svg"
import shopmatic1 from "../../../static/whySolv/shopmatic1.png"
import shopmatic2 from "../../../static/whySolv/shopmatic2.png"
import shopmatic3 from "../../../static/whySolv/shopmatic3.png"
import shopmatic4 from "../../../static/whySolv/shopmatic4.png"


// import Img from "gatsby-image"
// import { useStaticQuery, graphql } from "gatsby"
import "./whySolv.scss"


const WhySolv = (props) => {
//     const data = useStaticQuery(graphql`
//     query MyQuery1 {
//         file(relativePath: {eq: "icon.png"}) {
//         childImageSharp {
//           fluid {
//             ...GatsbyImageSharpFluid_tracedSVG
//           }
//         }
//       }
//     }  
//   `
//   )

    const getImage = (iconName) => {
        switch (iconName) {
            case 'career1' : return (
                    <div className='imgContainer'>
                        <img  className='icon' src={career1} alt="icon1" />
                    </div>
                )
                case 'career2' : return (
                    <div className='imgContainer'>
                        <img  className='icon' src={career2} alt="icon1" />
                    </div>
                )
                case 'career3' : return (
                    <div className='imgContainer'>
                        <img  className='icon' src={career3} alt="icon1" />
                    </div>
                )
                case 'career4' : return (
                    <div className='imgContainer'>
                        <img  className='icon' src={career4} alt="icon1" />
                    </div>
                )
                case 'career5' : return (
                    <div className='imgContainer'>
                        <img  className='icon' src={career5} alt="icon1" />
                    </div>
                )
                case 'career6' : return (
                    <div className='imgContainer'>
                        <img  className='icon' src={career6} alt="icon1" />
                    </div>
                )
                case 'home1' : return (
                    <div className='imgContainer'>
                        <img  className='icon' src={home1} alt="icon1" />
                    </div>
                )
                case 'home2' : return (
                    <div className='imgContainer'>
                        <img  className='icon' src={home2} alt="icon1" />
                    </div>
                )
                case 'home3' : return (
                    <div className='imgContainer'>
                        <img  className='icon' src={home3} alt="icon1" />
                    </div>
                )
                case 'home4' : return (
                    <div className='imgContainer'>
                        <img  className='icon' src={home4} alt="icon1" />
                    </div>
                )
                case 'home5' : return (
                    <div className='imgContainer'>
                        <img  className='icon' src={home5} alt="icon1" />
                    </div>
                )
                case 'home6' : return (
                    <div className='imgContainer'>
                        <img  className='icon' src={home6} alt="icon1" />
                    </div>
                )
                case 'shopmatic1' : return (
                    <div className='imgContainer'>
                        <img  className='icon' src={shopmatic1} alt="icon1" />
                    </div>
                )
                case 'shopmatic2' : return (
                    <div className='imgContainer'>
                        <img  className='icon' src={shopmatic2} alt="icon1" />
                    </div>
                )
                case 'shopmatic3' : return (
                    <div className='imgContainer'>
                        <img  className='icon' src={shopmatic3} alt="icon1" />
                    </div>
                )
                case 'shopmatic4' : return (
                    <div className='imgContainer'>
                        <img  className='icon' src={shopmatic4} alt="icon1" />
                    </div>
                )
                        
        }
    }
    return (
        <div>
            <div className={props.label?'choose-solv-default':'choose-solv'}>
                <div className='numeric'>{props.number}</div>                
                {getImage(props.icon)}
                {/* <div className='imgContainer'>
                    <Img fluid={data.file.childImageSharp.fluid} alt="icon" />
                </div> */}
                <div>
                    <div className='label'>{props.label}</div>
                    <div className='solv-text'>{props.description}</div>
                </div>
            </div>
        </div>
    )
}

WhySolv.defaultProps = {
    number: "1",
    icon: "iconDef",
    label: "",
    description:""
  }
export default WhySolv
