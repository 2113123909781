import React from "react"
import "./_button.scss"
import PropTypes from "prop-types"

const Button = ({type, label, icon, handleClick}) => {
  const BTNTYPES = ['primary', 'secondary', 'outline', 'primary-squared'];
  // checking values and falling back to default
  const testBtnType = BTNTYPES.includes(type) ? type : BTNTYPES[0];
  const className = `btn-solv ${testBtnType}`

  return (
    <button className={className} onClick={handleClick}>
      {label} {icon && icon}
    </button>
  )
}

Button.defaultProps = {
  type: "primary",
}

Button.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.element,
  handleClick: PropTypes.func
}

export default Button