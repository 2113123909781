import React from "react"
import styles from "./customCarouselArrow-css-module.module.scss"
import prev from '../../assets/carouselArrows/leftArrow.svg'
import prevRound from '../../assets/carouselArrows/leftRoundArrow.svg'
import nextRound from '../../assets/carouselArrows/rightRoundArrow.svg'

function CustomCarouselArrow(props) {
    const { type, className, style, onClick } = props;

    const imageMap = {
        Prev:  <img src={prev}  alt='newsPic'/>,
        Next:  <img src={prev}  alt='newsPic'/>,
        PrevRound:  <img src={prevRound}  alt='newsPic'/>,
        NextRound:  <img src={nextRound}  alt='newsPic'/>,
    }

    return (
    <div className={[className, styles[type]].join(' ')} style={{ ...style}} onClick={onClick}>
        {imageMap[type]}
    </div>  
    );
  }


export default CustomCarouselArrow