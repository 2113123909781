import React from 'react';
import './SupplyChainFinance.scss';

import CheckpointVoilet from '../../../../static/checkpoint-voilet.svg'
// import supplyChainFinance from '../../../../static/supplyChainFinance.png'
// import supplyChainHalfBg from '../../../../static/supplyChainHalfBg.png'
import ReactPlayer from 'react-player'

const SupplyChainFinance = () => {
    function handleChange(event) {
        let ScfFlyer;
        const fileName = `SCF-flyer-${event.target.value}.pdf`
        if (event.target.value !== '') {
            ScfFlyer = `/SCF-flyer-${event.target.value}.pdf`
            fetch(ScfFlyer).then(response => {
                response.blob().then(blob => {
                    // Creating new object of PDF file
                    const fileURL = window.URL.createObjectURL(blob);
                    // Setting various property values
                    let alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.download = fileName;
                    alink.click();
                })
            });
        } else {
            return null;
        }
    }
    return (
        <div className='scf-main'>
            <div className='scf-container'>
                <div>
                    <div className='scf-heading'>
                        <div className='section-scf-heading'>
                            <span>Supply Chain Finance</span>
                        </div>
                        <div className='section-scf-subheading'>
                            <span>Financing for inventory and receivables</span>
                        </div>
                    </div>
                    <div className='scf-content'>
                        <div className='col'>
                            <div className='row'>
                                <div className={['wrapper'].join(" ")}>
                                    <div className={['section-description', 'divInside'].join(" ")}>
                                        <span>The Solv dealer finance program is an anchor-led invoice financing-based program. The program helps dealers and distributors leverage their relationship with their anchor by procuring financing for inventory and receivables at attractive rates from Solv’s network of financial institutions. Managing requirements for financing during peak sales seasons is also simplified. The Solv dealer finance program is powered by Solv’s blockchain-enabled digital platform.</span>
                                    </div>
                                </div>
                            </div>
                            <div className='scf-video'>
                                <ReactPlayer
                                    url='https://youtu.be/tTX2snxVgXY'
                                    className='react-player'
                                    width="100%"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className='col'>
                        <div className='row'>
                            <div className={['scf-checkpoints', 'divInside'].join(" ")}>
                                <div className='scf-checkpoint-item'>
                                    <img className='scfCheckListImage' src={CheckpointVoilet} /><span className='checkpoint-text'>Collateral Free</span>
                                </div>
                                <div className='scf-checkpoint-item'>
                                    <img className='scfCheckListImage' src={CheckpointVoilet} /><span className='checkpoint-text'>Competitive interest rates</span>
                                </div>
                                <div className='scf-checkpoint-item'>
                                    <img className='scfCheckListImage' src={CheckpointVoilet} /><span className='checkpoint-text'>Assured and steady source of financing</span>
                                </div>
                                <div className='scf-checkpoint-item'>
                                    <img className='scfCheckListImage' src={CheckpointVoilet} /><span className='checkpoint-text'>Improved liquidity enabling expanded inventory</span>
                                </div>
                                <div className='scf-checkpoint-item'>
                                    <img className='scfCheckListImage' src={CheckpointVoilet} /><span className='checkpoint-text'>Additional finance available during peak season</span>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='row-flyer'>
                                <label>Download brochure: &nbsp;</label>
                                <select onChange={handleChange}>
                                    <option value="" defaultValue >Select</option>
                                    <option value="English">SCF Flyer-English</option>
                                    <option value="Hindi">SCF Flyer-Hindi</option>
                                    <option value="Gujarati">SCF Flyer-Gujarati</option>
                                    <option value="Kannada">SCF Flyer-Kannada</option>
                                    <option value="Tamil">SCF Flyer-Tamil</option>
                                    <option value="Telugu">SCF Flyer-Telugu</option>
                                    <option value="Malayalam">SCF Flyer-Malayalam</option>
                                </select>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    )
}

export default SupplyChainFinance