import React from 'react'

import './TrustedFIPartners.scss'
import DavintaLogo from '../../../assets/finance/davinta.png'
import EPayLaterLogo from '../../../assets/finance/ePayLater.png'
import IndifyLogo from '../../../assets/finance/indifi.png'
import SCBLogo from '../../../assets/finance/standard-chartered.png'
import RupiFi from '../../../assets/finance/Rupifi.png'
import HeroFinCorp from '../../../assets/finance/HeroFinCorp.jpg'
import mintifi from '../../../assets/finance/mintifi.jpg';
import MuthootFincorp from '../../../assets/finance/muthoot-fincorp.png';
const TrustedFIPartners = () => {
    return (
        <div className='partners-container'>
            <div className='partners-heading-section'>
                <div className='heading-wrapper'>
                    <span className='partners-heading'>
                        Our trusted FI partners
                    </span>
                </div>
                <div className='heading-wrapper'>
                    <span className='partners-subheading'>
                    We are able to match small businesses with the right FI because of our extensive network of FI partners.
                    </span>
                </div>
            </div>
            <div className='partners-image-section'>
            <div className='image-row'>
                    <div className='image-div'><img className='partner-image' src={IndifyLogo}/></div>
                    <div className='image-div'><img className='partner-image' src={DavintaLogo}/></div> 
                    <div className='image-div'><img className='partner-image' src={HeroFinCorp}/></div>      
                    <div className='image-div'><img className='partner-image' src={mintifi}/></div> 
                    <div className='image-div'><img style={{width:'60%'}} className='partner-image' src={RupiFi}/></div>
                </div>
                <div className='image-row'>
                      
                    <div className='image-div'><img className='partner-image' src={SCBLogo}/></div>
                    
                    <div className='image-div'><img className='partner-image' src={EPayLaterLogo}/></div>
                    <div className='image-div'><img className='partner-image' src={MuthootFincorp}/></div> 
                    
                    <div className='image-div'><b className='text-img'>Cholamandalam <br></br> Finance</b></div> 
                    <div className='image-div'><b className='text-img'>Adani <br></br> Capital</b></div>

                    
                    
                </div>
            </div>
        </div>
    )
}

export default TrustedFIPartners